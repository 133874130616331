import { API, graphqlOperation } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { listContacts } from '../graphql/queries'

const useContacts = (tags, nextPage) => {
    const [items, setItems] = useState([])
    const [nextToken, setNextToken] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const buildFilter = (theOrTags) => {
        const orTags = [...theOrTags]
        return orTags.length === 1 ? {
            tags: {
                contains: orTags[0]
            }
        } : {
            or: orTags.map(tag => ({
                tags: {
                    contains: tag
                }
            }))
        }
    }

    const fetchContacts = async (orTags) => {
        setLoading(true)
        setError(null)
        let filter = buildFilter(orTags)
        let variables = (null === orTags) || (undefined === orTags || (0 === orTags.size)) ?
            {
                limit: 20
            } : {
                filter,
                limit: 250,
            }
        try {
            const limit = 20
            let gqlData
            let resultItems = []
            let nextToken = nextPage
            const appendResults = (null !== nextPage)
            do {
                variables.nextToken = nextToken
                gqlData = await API.graphql(graphqlOperation(listContacts, variables))
                resultItems = [...resultItems, ...gqlData.data.listContacts.items]
                nextToken = gqlData.data.listContacts.nextToken
            } while (nextToken && resultItems.length <= limit)
            if (appendResults) {
                setItems(prev => [...prev, ...resultItems])
            } else {
                setItems(resultItems)
            }
            setNextToken(nextToken)
        } catch (err) {
            console.log('error fetching contacts', err)
            setError('ERROR: ' + err?.errors ? err.errors[0].message : err.message)
        }
        setLoading(false)
    }

    const allTags = [...tags].toString()

    useEffect(async () => {
        setItems([])
        setNextToken(null)
    }, [allTags])

    useEffect(async () => {
        await fetchContacts(tags)
        return () => {
            //cleanup
        }
    }, [allTags, nextPage])

    return { contacts: items, nextToken, loading, error }
}

export default useContacts