import Amplify from "aws-amplify"
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import awsExports from "./aws-exports"
//import registerServiceWorker from './registerServiceWorker'
import { AuthProvider } from './helpers/AuthContext'
import './index.css'
import reportWebVitals from './reportWebVitals'
Amplify.configure(awsExports)

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

//registerServiceWorker()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
