import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import useSendGridSender from '../../hooks/useSendGridSender'


const BlastStepDesign = ({ blast }) => {
    const [template, setTemplate] = useState({})
    const {
        id: blastID,
        templateId,
        shortDescription,
    } = blast
    const { callGetTemplateApi, sendEmail, callGetRecipientsForAudience } = useSendGridSender()

    useEffect(async () => {
        if ((undefined === templateId) || (null === templateId)) return
        const template = await callGetTemplateApi(templateId)
        setTemplate(template)
    }, [templateId])

    const SendTestEmail = async () => {
        let audience = 'user'
        let result = await sendEmail(blastID, audience)
        console.log('sendEmail:', result)
        alert(result)
    }

    const SendTestEmails = async () => {
        let audience = 'testers'
        let result = await sendEmail(blastID, audience)
        console.log('sendEmail:', result)
        alert(result)
    }

    const GetEmailRecipients = async () => {
        let audience = 'intoxalock'
        let result = await callGetRecipientsForAudience(blastID, audience, [])
        const recipients = JSON.parse(result)
        console.log('GetEmailRecipients:', recipients)
        alert(`Segment matches ${recipients.length} recipients`)
    }

    const newspaperIcon = <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="newspaper" className="inline-block w-6 h-6 svg-inline--fa fa-newspaper fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M552 64H112c-20.858 0-38.643 13.377-45.248 32H24c-13.255 0-24 10.745-24 24v272c0 30.928 25.072 56 56 56h496c13.255 0 24-10.745 24-24V88c0-13.255-10.745-24-24-24zM48 392V144h16v248c0 4.411-3.589 8-8 8s-8-3.589-8-8zm480 8H111.422c.374-2.614.578-5.283.578-8V112h416v288zM172 280h136c6.627 0 12-5.373 12-12v-96c0-6.627-5.373-12-12-12H172c-6.627 0-12 5.373-12 12v96c0 6.627 5.373 12 12 12zm28-80h80v40h-80v-40zm-40 140v-24c0-6.627 5.373-12 12-12h136c6.627 0 12 5.373 12 12v24c0 6.627-5.373 12-12 12H172c-6.627 0-12-5.373-12-12zm192 0v-24c0-6.627 5.373-12 12-12h104c6.627 0 12 5.373 12 12v24c0 6.627-5.373 12-12 12H364c-6.627 0-12-5.373-12-12zm0-144v-24c0-6.627 5.373-12 12-12h104c6.627 0 12 5.373 12 12v24c0 6.627-5.373 12-12 12H364c-6.627 0-12-5.373-12-12zm0 72v-24c0-6.627 5.373-12 12-12h104c6.627 0 12 5.373 12 12v24c0 6.627-5.373 12-12 12H364c-6.627 0-12-5.373-12-12z"></path></svg>
    const mailIcon = <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="mail-bulk" className="inline-block w-6 h-6 svg-inline--fa fa-mail-bulk fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M160 448c-25.6 0-51.2-22.4-64-32-64-44.8-83.2-60.8-96-70.4V480c0 17.67 14.33 32 32 32h256c17.67 0 32-14.33 32-32V345.6c-12.8 9.6-32 25.6-96 70.4-12.8 9.6-38.4 32-64 32zm128-192H32c-17.67 0-32 14.33-32 32v16c25.6 19.2 22.4 19.2 115.2 86.4 9.6 6.4 28.8 25.6 44.8 25.6s35.2-19.2 44.8-22.4c92.8-67.2 89.6-67.2 115.2-86.4V288c0-17.67-14.33-32-32-32zm256-96H224c-17.67 0-32 14.33-32 32v32h96c33.21 0 60.59 25.42 63.71 57.82l.29-.22V416h192c17.67 0 32-14.33 32-32V192c0-17.67-14.33-32-32-32zm-32 128h-64v-64h64v64zm-352-96c0-35.29 28.71-64 64-64h224V32c0-17.67-14.33-32-32-32H96C78.33 0 64 14.33 64 32v192h96v-32z"></path></svg>

    const disableSend = (
        (null === blast.fromEmail) || (null === blast.replyToEmail) ||
        (null === blast.fromName) || (null === blast.replyToName) ||
        (null == blast.templateId))

    const InfoStepContent = <>
        <div className='grid min-w-full grid-cols-2 prose'>
            <div>
                <h4>{newspaperIcon} Project Description</h4>
                <p>{shortDescription}</p>
                <h4>Sender Details</h4>
                <table>
                    <tbody>
                        <tr><th>From Name</th><td>{blast.fromName}</td></tr>
                        <tr><th>From Email</th><td>{blast.fromEmail}</td></tr>
                        <tr><th>Reply-To Name</th><td>{blast.replyToName}</td></tr>
                        <tr><th>Reply-To Email</th><td>{blast.replyToEmail}</td></tr>
                    </tbody>
                </table>
                <h4>Test Actions</h4>
                <div className="flex flex-col space-y-2">
                    <button
                        type="button"
                        className="ampyButton"
                        disabled={disableSend} onClick={SendTestEmail}>Send TEST to me</button>
                    <button
                        type="button"
                        className="ampyButton"
                        disabled={disableSend} onClick={SendTestEmails}>Send to all testers</button>
                    <button
                        type="button"
                        className="ampyButton"
                        disabled={disableSend} onClick={GetEmailRecipients}>Get Email Recipients</button>
                </div>
            </div>
            <div>
                <h4>{mailIcon} Email Preview</h4>
                <p>Subject: {template.subject || "n/a"}</p>
                <p>Template Name: {template.name || "n/a"}</p>
                {template && <img src={template.thumbnail_url} />}
            </div>
        </div>
    </>

    return (
        <>
            { InfoStepContent}
        </>
    )
}

BlastStepDesign.propTypes = {
    blast: PropTypes.any
}

export default BlastStepDesign