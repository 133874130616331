import PropTypes from 'prop-types'
import React from 'react'

function Loader({ active }) {
    return (
        <>
            {active &&
                <div
                    style={{ borderTopColor: 'green' }}
                    className="m-2 ease-linear border-8 border-green-200 rounded-full w-14 h-14 animate-spin"
                />
            }
        </>
    )
}

Loader.propTypes = {
    active: PropTypes.bool
}

export default Loader