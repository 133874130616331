// The UI has a dropdown box for each column allowing the user to tell us how to import
// that column. E.g. if we should ignore it, or if it's the email address, or just a
// misc metadata column they'll use for dynamic substitutions.
//
// contactsMetadata is an array of reserved column types and 'metadata' columns from past imports.
// If a column (in the to be imported data) matches a metadata field then try to set the dropdown box.
const alignColumnsToDataTypes = (csvHeadings, contactsMetadata) => {
    let initialMetaState = {}
    csvHeadings.forEach(column => {
        //console.log(`Trying to clasify each column heading: `, column)
        const meta = contactsMetadata.find(({ fieldname }) => column.toLowerCase() === fieldname.toLowerCase())
        if (meta) {
            switch (meta.type) {
                case 'METADATA_TEXT':
                case 'METADATA_NUMBER':
                case 'METADATA_DATE':
                case 'METADATA_BOOLEAN':
                    initialMetaState[column] = meta.fieldname
                    break
                case 'EMAIL':
                    initialMetaState[column] = 'EMAIL'
                    break
                case 'NAME':
                    initialMetaState[column] = 'NAME'
                    break
                case 'CONTACT_ID':
                    initialMetaState[column] = 'CONTACT_ID'
                    break
                case 'FIRST_NAME':
                    initialMetaState[column] = 'FIRST_NAME'
                    break
                case 'LAST_NAME':
                    initialMetaState[column] = 'LAST_NAME'
                    break
                case 'DNC_EMAIL':
                    initialMetaState[column] = 'DNC_EMAIL'
                    break
                default:
                    alert('unknown type')
                    break
            }
        } else {
            switch (column.toLowerCase()) {
                case 'email':
                case 'email address':
                case 'email_address':
                case 'email-address':
                case 'emailaddress':
                case 'c_email':
                    initialMetaState[column] = 'EMAIL'
                    break
                case 'noemail':
                case 'no_email':
                case 'no-email':
                case 'no email':
                    initialMetaState[column] = 'DNC_EMAIL'
                    break
                case 'name':
                case 'name_line1':
                case 'c_full_name':
                    initialMetaState[column] = 'NAME'
                    break
                case 'firstname':
                case 'first name':
                case 'first_name':
                    initialMetaState[column] = 'FIRST_NAME'
                    break
                case 'lastname':
                case 'last name':
                case 'last_name':
                    initialMetaState[column] = 'LAST_NAME'
                    break
                case 'id':
                case 'cid':
                case 'account':
                case 'customer_id':
                case 'contact id':
                case 'contact-id':
                case 'contact_id':
                case 'c_contactid':
                    initialMetaState[column] = 'CONTACT_ID'
                    break
                default:
                    initialMetaState[column] = 'DO NOT IMPORT' // METADATA_TEXT
                    break
            }
        }
    })
    return initialMetaState
}

export default alignColumnsToDataTypes