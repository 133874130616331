import React from 'react'
import PropTypes from 'prop-types'

function Message({
    positive,
    negative,
    warning,
    info,
    icon,
    header,
    content,
    children
}) {


    let svgIcon = <></>
    switch (icon) {
        case 'bullhorn':
            svgIcon = <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bullhorn" className="fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M576 240c0-23.63-12.95-44.04-32-55.12V32.01C544 23.26 537.02 0 512 0c-7.12 0-14.19 2.38-19.98 7.02l-85.03 68.03C364.28 109.19 310.66 128 256 128H64c-35.35 0-64 28.65-64 64v96c0 35.35 28.65 64 64 64h33.7c-1.39 10.48-2.18 21.14-2.18 32 0 39.77 9.26 77.35 25.56 110.94 5.19 10.69 16.52 17.06 28.4 17.06h74.28c26.05 0 41.69-29.84 25.9-50.56-16.4-21.52-26.15-48.36-26.15-77.44 0-11.11 1.62-21.79 4.41-32H256c54.66 0 108.28 18.81 150.98 52.95l85.03 68.03a32.023 32.023 0 0 0 19.98 7.02c24.92 0 32-22.78 32-32V295.13C563.05 284.04 576 263.63 576 240zm-96 141.42l-33.05-26.44C392.95 311.78 325.12 288 256 288v-96c69.12 0 136.95-23.78 190.95-66.98L480 98.58v282.84z"></path></svg>
            break
        case 'warning sign':
            svgIcon = <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle" className="fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path></svg>
            break
        case 'cloud upload':
            svgIcon = <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cloud-upload-alt" className="fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z"></path></svg>
            break
        default:
            svgIcon = <h1>{icon}</h1>
            break
    }

    // Do not purge, these styles are used
    // bg-green-100
    // bg-red-100
    // bg-yellow-100
    // bg-blue-100
    // bg-gray-100
    // border-green-200
    // border-red-200
    // border-yellow-200
    // border-blue-200
    // border-gray-200
    // text-green-900
    // text-red-900
    // text-yellow-900
    // text-blue-900
    // text-gray-900
    let bgColor = positive ? 'green' : negative ? 'red' : warning ? 'yellow' : info ? 'blue' : 'gray'
    let textColor = positive ? 'green' : negative ? 'red' : warning ? 'yellow' : info ? 'blue' : 'gray'

    return (
        <div className={`flex p-4 mb-2 text-${textColor}-900 bg-${bgColor}-100 border-${bgColor}-200 border-2 text-gray-50 shadow-md`}>
            <div className='w-12 h-12'>{svgIcon}</div>
            <div className='container ml-4'>
                <div className={`text-xl font-semibold`}>{header}</div>
                <div className={`text-base font-normal`}>{content}</div>
                <div>{children}</div>
            </div>
        </div>
    )
}

Message.propTypes = {
    positive: PropTypes.bool,
    negative: PropTypes.bool,
    warning: PropTypes.bool,
    info: PropTypes.bool,
    icon: PropTypes.string,
    header: PropTypes.string,
    content: PropTypes.node,
    children: PropTypes.any
}

export default Message