function forceDownload(data, mimeType, filename) {
    var tag = document.createElement('a')
    const dataUri = encodeURIComponent(data)
    tag.href = `data:${mimeType},${dataUri}`
    tag.download = filename
    document.body.appendChild(tag)
    tag.click()
    document.body.removeChild(tag)
}

const downloadData = (data, mimeType, filename) => {
    forceDownload(data, mimeType, filename)
}

export default downloadData