import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from "react-router-dom"
import { Waypoint } from 'react-waypoint'

const ContactsTable = props => {
    const { contacts, loading, nextToken, error, loadMoreContent } = props
    const history = useHistory()
    //console.log('nextToken', nextToken)
    const contactsTable =
        <div className="overflow-y-auto border-b h-96">
            <table className="w-full table-auto" >
                <thead>
                    <tr className="p-10 m-8">
                        <th className="sticky top-0 p-2 bg-red-800 border-r text-red-50">Cust Id</th>
                        <th className="sticky top-0 bg-red-800 border-r text-red-50">Name</th>
                        <th className="sticky top-0 bg-red-800 border-r text-red-50">Email</th>
                        <th className="sticky top-0 w-24 bg-red-800 border-r text-red-50">Tags</th>
                    </tr>
                </thead>
                <tbody className="font-sans text-base font-normal">
                    {(contacts && contacts.length > 0) ? contacts.map((contact, idx) =>
                        <tr key={idx}
                            className="cursor-pointer even:bg-gray-50 hover:bg-yellow-100"
                            onClick={() => { history.push(`/contacts/${contact.id}`) }}
                            style={{
                                backgroundColor: contact.dncEmail ? '#edb5b5' : ''
                            }}
                        >
                            <td className="border">{contact.ccid}</td>
                            <td className="border">{contact.name}</td>
                            <td className='border blurryField'>
                                <span className='neverBlurryField'>
                                    {contact.dncEmail && <strong>{contact.dncEmail}</strong>}</span>
                                    &nbsp;&nbsp;&nbsp;<span>{contact.email}</span>
                            </td>
                            <td className="border">
                                {(nextToken && (((contacts.length > 10) && (idx === contacts.length - 10)) || (contacts.length < 10 && (1 === contacts.length - idx))))
                                    &&
                                    <>
                                        <Waypoint
                                            key={nextToken}
                                            onEnter={loadMoreContent} />
                                        waypoint
                                    </>}
                                {contact.tags && contact.tags.map((tag, i) => <span key={i} className="pill">{tag}</span>)}
                            </td>
                        </tr>
                    ) : !loading && (
                        <tr>
                            <td>
                                {nextToken ?
                                    <>
                                        <Waypoint key={nextToken} onEnter={loadMoreContent} />
                                        `There is more data`
                                    </> : `No contacts found`}
                            </td>
                            <td></td>
                            <td></td>
                        </tr>)
                    }
                    {(loading || error) &&
                        <tr>
                            <td>
                                <span>{`LOADING`} </span>
                                {error && <p>An error occured</p>}
                            </td>
                            <td>{nextToken && `There is more data...`}</td>
                            <td></td>
                        </tr>
                    }
                </tbody>
            </table >
        </div>

    return contactsTable
}

ContactsTable.propTypes = {
    contacts: PropTypes.array,
    loading: PropTypes.bool,
    nextToken: PropTypes.string,
    error: PropTypes.object,
    loadMoreContent: PropTypes.func
}
export default ContactsTable
