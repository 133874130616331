import React from 'react'
import { NavLink } from "react-router-dom"

const Footer = () => (
    <footer className="flex justify-center px-4 text-red-100 bg-red-800">
        <div className="container py-6">
            <div className="flex flex-col items-center justify-between md:flex-row">
                <div>
                    <span className="text-base font-normal">© Ampymail 2021, all rights reserved.</span>
                </div>
                <div className="flex mt-4 md:m-0">
                    <div className="-mx-4 font-medium">
                        <NavLink to="/privacy" className="px-4 text-sm navlink">Privacy Policy</NavLink>
                        <NavLink to="/terms" className="px-4 text-sm navlink">Terms of Use</NavLink>
                        <NavLink to="/about" className="px-4 text-sm navlink">About</NavLink>
                        <NavLink to="/contact" className="px-4 text-sm navlink">Contact Us</NavLink>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)

// Footer.propTypes = {
//     children: PropTypes.any
// }

export default Footer