/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'


const isReservedKey = key => new Set([
    'and', 'or', 'not',
    'booleanIsTrue',
    'isEmpty',
    'stringEQ', 'stringNE', 'stringGT', 'stringGE', 'stringLT', 'stringLE', 'stringStarts', 'stringNStarts', 'stringContains', 'stringNContains', 'stringEnds', 'stringNEnds',
    'stringIEQ', 'stringINE', 'stringIGT', 'stringILT', 'stringIGE', 'stringILE', 'stringIStarts', 'stringINStarts', 'stringIContains', 'stringINContains', 'stringIEnds', 'stringINEnds',
    'dateIsSameDay', 'dateIsBefore', 'dateIsBetween', 'dateIsAfter'
]).has(key)

const isArray = a => {
    return (!!a) && (a.constructor === Array)
}

const isObject = a => {
    return (!!a) && (a.constructor === Object)
}

const ruleContainer = (rule, idx) => {
    console.log(`rule = `, rule)
    const segmentComponents = Object.keys(rule).map(key => {
        console.log(`key`, key)
        const ruleValue = rule[key]
        console.log(`ruleValue`, ruleValue)
        if (isReservedKey(key)) {
            if ('or' === key) {
                if (!isArray(ruleValue)) {
                    throw new Error('ERROR value for OR must be an array')
                }
                // show all or conditions
                return <OrConditions key={key} rule={ruleValue} />
                //const isAnyConditonMet = meetsAnyConditionTrue(ruleValue, recipient)
                //if (!isAnyConditonMet) return false
            } else if ('and' === key) {
                if (!isArray(ruleValue)) {
                    throw new Error('ERROR value for AND must be an array')
                }
                // show all AND conditions
                return <div> SHOW ALL AND CONDITIONS</div>
                //const allConditionsMet = meetsAllConditionsThrows(ruleValue, recipient)
                //if (!allConditionsMet) return false
            } else if ('not' === key) {
                // if (isArray(ruleValue)) {
                //     // not negates the "and" rule
                //     const allConditionsMet = meetsAllConditionsThrows(ruleValue, recipient)
                //     if (allConditionsMet) return false // if !!allConditionsMet, no match, stop filtering
                // } else {
                //     const result = meetsConditionThrows(ruleValue, undefined, recipient)
                //     if (result) return false
                // }
                return <div>NOT condition </div>
            } else {
                //const r = isConditionTrue(key, ruleValue, contactValue)
                return <div>show condition {key} </div>
            }
        } else {
            if (isArray(ruleValue)) {
                throw new Error(`Value for ${key} must be object {compareMethod : valueToCompareWith}. Got array.`)
            }
            else if (isObject(ruleValue)) {
                console.log(`SEGMENT-RULE`, key)
                return <SegmentRule key={key} field={key} ops={ruleValue} />
            }
            else throw new Error(`ERROR value for ${key} must be array or object`, rule)
        }
    })
    return <div key={idx} className='p-2 m-4 bg-blue-500'>{segmentComponents}</div>
}

const SegmentRule = ({ field, ops }) => {
    console.log(`field`, field)
    console.log(`ops`, ops)
    return <div className='p-2 m-4 bg-blue-400'>
        <p><strong>{field}</strong></p>
        {Object.keys(ops).map(op => {
            //const cValue = getValueForKeyFromRecipient(key, recipient)
            const value = ops[op]
            let valueString
            if (isObject(value)) {
                valueString = JSON.stringify(value)
            } else if ("string" === typeof value) {
                valueString = value
            } else if ("boolean" === typeof value) {
                valueString = value.toString()
            } else if ("number" === typeof value) {
                valueString = value.toString()
            } else {
                valueString = "??"
            }
            return <div className='p-2 m-2 bg-blue-300' key={op}> {op} {valueString} </div>
        })}
    </div>
}

SegmentRule.propTypes = {
    field: PropTypes.any,
    ops: PropTypes.any,
}

const OrConditions = ({ rule }) => {
    return <div className='p-2 m-4 bg-green-400'>
        <p><strong>OR ...</strong></p>
        {ruleContainer(rule, 0)}
    </div>
}
OrConditions.propTypes = {
    rule: PropTypes.any,
    ops: PropTypes.object
}

function SegmentRules({ rules }) {

    console.log(`rules`, rules)
    // const segmentRules = [
    //     {
    //         "offerId1": { "stringStarts": "LMM210" },
    //         "or": [
    //             { "offerId2": { "isEmpty": true } },
    //             { "offerId2": { "stringEQ": "DIN2104A" } },
    //         ]
    //     }
    // ]

    const segmentContainers = (rules) => {
        if (isArray(rules)) {
            console.log(`MULTIPLE RULES`)
            return rules.map((r, i) => ruleContainer(r, i))
        } else {
            console.log(`SINGLE RULE`)
            return ruleContainer(rules, 0)
        }
    }

    return (
        <div>
            {segmentContainers(rules)}
        </div>)
}

SegmentRules.propTypes = {
    rules: PropTypes.object,
}

export default SegmentRules
