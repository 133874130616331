import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useHistory } from "react-router-dom"
import IconButton from '../../components/IconButton'
import downloadData from '../../helpers/downloadData'
import objectToCSV from '../../helpers/objectToCSV'
import useEvents from '../../hooks/useEvents'

const BlastStepReports = ({ blast }) => {
  const history = useHistory()
  const { fetchAllEvents } = useEvents()
  const [allEventsData, setAllEventsData] = useState([])

  const fetchEventsData = async () => {
    const events = await fetchAllEvents(blast.id)
    setAllEventsData(events)
  }
  const svgCheckIcon =
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className='inline-block w-6 h-6 fill-current'>
      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
    </svg>

  const svgDownloadIcon =
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className='inline-block w-8 h-8 '>
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
    </svg>

  const svgHistoryIcon =
    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="history" className="inline-block w-6 h-6 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z"></path></svg>

  const makeEventsTable = () => {
    if (allEventsData?.length === 0) return <><button className='ampyButton' onClick={fetchEventsData}>Request Events Data</button></>
    let eventsByContact = []
    allEventsData.forEach(event => {
      const contactId = event.contactID //event.Contact.items[0].id
      if (undefined === eventsByContact[contactId]) {
        eventsByContact[contactId] = {
          contactId: contactId,
          email: event.Contact.items[0] ? event.Contact.items[0].email : 'MISSING',
          name: event.Contact.items[0] ? event.Contact.items[0].name : 'MISSING'
        }
      }
      eventsByContact[contactId][event.eventType] = event.eventType
    })

    const headerTitles = ['processed', 'delivered', 'opened', 'clicks', 'unsubscribed', 'complained', 'bounced', 'dropped']
    const headerEvents = ['processed', 'delivered', 'open', 'click', 'unsubscribe', 'complaint', 'bounce', 'dropped']
    if (blast.isIMSight) {
      console.log("This Blast is an IMsight")
      headerTitles.unshift('scanned')
      headerEvents.unshift('IMsightScan')
    }
    const headers = ['name', 'email', ...headerTitles]

    return <table>
      <thead>
        <tr>
          {headers.map((header) => <th key={header} className='p-4 border'>{header}</th>)}
        </tr>
      </thead>
      <tbody>
        {Object.keys(eventsByContact).map(key => {
          const event = eventsByContact[key]
          return (
            <tr key={key}
              onClick={() => { history.push(`/contacts/${event.contactId}`) }}
              style={{ cursor: 'cell' }}>
              <td className='border'>{event.name}</td>
              <td className='border'>{event.email}</td>
              {headerEvents.map((header) => <td key={header} className='text-center border'>{event[header] && svgCheckIcon}</td>)}
            </tr>)
        })
        }
      </tbody>
    </table>
  }

  const eventsByContactTable = makeEventsTable()

  const getAllEvents = async () => {
    return (await fetchAllEvents(blast.id)).map(event => ({
      timestamp: new Date(+event.timestamp * 1000).toISOString(),
      contactID: event.contactID,
      ccid: event.Contact.items[0].ccid,
      name: event.Contact.items[0].name,
      email: event.Contact.items[0].email,
      eventType: event.eventType,
      subType: event.subType,
      reason: event.reason,
      status: event.status,
      response: event.response,
      attempt: event.attempt,
      //asm_group_id: event.asm_group_id,
      useragent: event.useragent,
      ip: event.ip,
      url: event.url
    }))
  }

  const downloadAllEvents = async (whichType) => {
    const allEvents = await getAllEvents()
    if (0 === allEvents?.length) {
      alert("No events returned")
      return
    }
    const data = allEvents.map(e => {
      e.contactID = e.ccid
      delete e.ccid // <-- mutates object in allEvents
      return e
    })
    const filename = `all events ${blast.title}`
    if ('csv' === whichType) {
      downloadData(
        objectToCSV(data),
        'text/csv',
        `${filename}.csv`)
    } else if ('json' === whichType) {
      downloadData(
        JSON.stringify(data, null, 2),
        'text/json',
        `${filename}.json`)
    }
  }

  const downloadAllEventsByContact = async (whichType) => {
    const allEvents = await getAllEvents()
    if (undefined === allEvents) alert("error")
    if (0 === allEvents?.length) {
      alert("No events returned")
      return
    }

    let eventsByContact = []
    allEvents.forEach(event => {
      const contactId = event.contactID
      if (undefined === eventsByContact[contactId]) {
        eventsByContact[contactId] = {
          ccid: event.ccid,
          email: event.email ? event.email : 'MISSING',
          name: event.name ? event.name : 'MISSING'
        }
      }
      if (undefined === eventsByContact[contactId][event.eventType]) {
        // Save timestamp for the 1st event
        eventsByContact[contactId][event.eventType] = event.timestamp
      }
    })
    const data = Object.keys(eventsByContact).map(cid => ({
      contactId: eventsByContact[cid].ccid, // <-- report uses ccid
      name: eventsByContact[cid].name,
      email: eventsByContact[cid].email,
      processed: eventsByContact[cid].processed,
      delivered: eventsByContact[cid].delivered,
      opened: eventsByContact[cid].open,
      clicked: eventsByContact[cid].click,
      unsubscribed: eventsByContact[cid].unsubscribe,
      complained: eventsByContact[cid].complaint,
      bounced: eventsByContact[cid].bounce,
      dropped: eventsByContact[cid].dropped,
    }))
    const filename = `events by contact ${blast.title}`
    if ('csv' === whichType) {
      downloadData(
        objectToCSV(data),
        'text/csv',
        `${filename}.csv`)
    } else if ('json' === whichType) {
      downloadData(
        JSON.stringify(data, null, 2),
        'text/json',
        `${filename}.json`)
    }
  }

  const downloadTodo = () => {
    alert("TODO")
  }

  const reportsTable = <table className='mt-4'>
    <thead>
      <tr className='bg-gray-300'>
        <th >CSV</th>
        <th >JSON</th>
        <th>Export Reports</th>
      </tr>
    </thead>
    <tbody>
      {/* <!-- All Events --> */}
      <tr className='even:bg-gray-200' >
        <td className='border-collapse'>
          <IconButton svgIcon={svgDownloadIcon} className='text-green-600' onClick={async () => {
            await downloadAllEvents('csv')
          }} />
        </td>
        <td className='border-collapse'>
          <IconButton className='text-green-600' svgIcon={svgDownloadIcon} onClick={async () => {
            await downloadAllEvents('json')
          }} />
        </td>
        <td>All Events</td>
      </tr>
      {/* <!-- All Events By Contact --> */}
      <tr className='even:bg-gray-200' >
        <td className='border-collapse'>
          <IconButton className='text-green-600' svgIcon={svgDownloadIcon} onClick={async () => {
            await downloadAllEventsByContact('csv')
          }} />
        </td>
        <td className='border-collapse'>
          <IconButton className='text-green-600' svgIcon={svgDownloadIcon} onClick={async () => {
            await downloadAllEventsByContact('json')
          }} />
        </td>
        <td>Events by Contact</td>
      </tr>
      <tr className='even:bg-gray-200' >
        <td>
          <IconButton className='text-gray-600' svgIcon={svgDownloadIcon} onClick={downloadTodo} />
        </td>
        <td>
          <IconButton className='text-gray-600' svgIcon={svgDownloadIcon} onClick={downloadTodo} />
        </td>
        <td>all contacts that opened this email</td>
      </tr>
      <tr className='even:bg-gray-200' >
        <td>
          <IconButton className='text-gray-600' svgIcon={svgDownloadIcon} onClick={downloadTodo} />
        </td>
        <td>
          <IconButton className='text-gray-600' svgIcon={svgDownloadIcon} onClick={downloadTodo} />
        </td>
        <td>all contacts that opened AND interacted with this email</td>
      </tr>
      <tr className='even:bg-gray-200' >
        <td>
          <IconButton className='text-gray-600' svgIcon={svgDownloadIcon} onClick={downloadTodo} />
        </td>
        <td>
          <IconButton className='text-gray-600' svgIcon={svgDownloadIcon} onClick={downloadTodo} />
        </td>
        <td>all contacts that bounced, dropped, complained, or unsubscribed</td>
      </tr>
      <tr className='even:bg-gray-200' >
        <td>
          <IconButton className='text-gray-600' svgIcon={svgDownloadIcon} onClick={downloadTodo} />
        </td>
        <td>
          <IconButton className='text-gray-600' svgIcon={svgDownloadIcon} onClick={downloadTodo} />
        </td>
        <td>Initial commit</td>
      </tr>
    </tbody>
  </table>

  const ReportStepContent = <>
    {(allEventsData.length === 0) && <>
      <p>Load the event data before running reports</p>
      <button className='ampyButton' onClick={fetchEventsData}>Request Events Data</button>
    </>
    }
    {reportsTable}

    <h4 className='mt-4 mb-4 text-2xl'>{svgHistoryIcon} Project Events</h4>

    <section style={{ overflowY: 'auto', maxHeight: "90vh" }}>
      {eventsByContactTable}
    </section>
  </>


  return (
    <>
      <div>
        {ReportStepContent}
      </div>
    </>
  )
}

BlastStepReports.propTypes = {
  blast: PropTypes.any
}

export default BlastStepReports