import { API, graphqlOperation } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { listBlasts } from '../graphql/queries'

const useBlasts = () => {
    const [items, setItems] = useState([])

    useEffect(async () => {
        try {
            const gqlData = await API.graphql(graphqlOperation(listBlasts))
            const items = gqlData.data.listBlasts.items
            setItems(items)
        } catch (err) {
            console.log('error fetching blasts', err)
            alert('ERROR retrieving data from the database. (blasts)')
        }
    }, [])

    return items
}

export default useBlasts