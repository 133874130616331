/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const echo = /* GraphQL */ `
  query Echo($msg: String) {
    echo(msg: $msg)
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($templateId: String) {
    getTemplate(templateId: $templateId)
  }
`;
export const getRecipientStats = /* GraphQL */ `
  query GetRecipientStats($blastId: String) {
    getRecipientStats(blastId: $blastId)
  }
`;
export const getIMscanContactsValidationResults = /* GraphQL */ `
  query GetIMscanContactsValidationResults($key: String) {
    getIMscanContactsValidationResults(key: $key)
  }
`;
export const getScheduledSends = /* GraphQL */ `
  query GetScheduledSends {
    getScheduledSends
  }
`;
export const getAllEvents = /* GraphQL */ `
  query GetAllEvents($blastId: String) {
    getAllEvents(blastId: $blastId)
  }
`;
export const getRecipientsForAudience = /* GraphQL */ `
  query GetRecipientsForAudience(
    $blastId: String
    $audience: String
    $segmentId: String
    $tags: [String]
  ) {
    getRecipientsForAudience(
      blastId: $blastId
      audience: $audience
      segmentId: $segmentId
      tags: $tags
    )
  }
`;
export const getMetadataMapping = /* GraphQL */ `
  query GetMetadataMapping($id: ID!) {
    getMetadataMapping(id: $id) {
      id
      groups
      fieldname
      type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listMetadataMappings = /* GraphQL */ `
  query ListMetadataMappings(
    $filter: ModelMetadataMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMetadataMappings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groups
        fieldname
        type
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getMxRecord = /* GraphQL */ `
  query GetMxRecord($id: ID!) {
    getMxRecord(id: $id) {
      id
      response
      createdAt
      updatedAt
    }
  }
`;
export const listMxRecords = /* GraphQL */ `
  query ListMxRecords(
    $filter: ModelMxRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMxRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        response
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      groups
      contactID
      blastID
      eventType
      subType
      timestamp
      reason
      status
      response
      attempt
      asm_group_id
      useragent
      ip
      url
      imbAdr
      imbInHomeDate
      createdAt
      updatedAt
      owner
      Contact {
        items {
          id
          groups
          dncEmail
          ccid
          email
          name
          tags
          metadata
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        contactID
        blastID
        eventType
        subType
        timestamp
        reason
        status
        response
        attempt
        asm_group_id
        useragent
        ip
        url
        imbAdr
        imbInHomeDate
        createdAt
        updatedAt
        owner
        Contact {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      groups
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        name
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSegment = /* GraphQL */ `
  query GetSegment($id: ID!) {
    getSegment(id: $id) {
      id
      groups
      title
      description
      rules
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSegments = /* GraphQL */ `
  query ListSegments(
    $filter: ModelSegmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSegments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        title
        description
        rules
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      groups
      dncEmail
      ccid
      email
      name
      tags
      metadata
      createdAt
      updatedAt
      Events {
        items {
          id
          groups
          contactID
          blastID
          eventType
          subType
          timestamp
          reason
          status
          response
          attempt
          asm_group_id
          useragent
          ip
          url
          imbAdr
          imbInHomeDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        dncEmail
        ccid
        email
        name
        tags
        metadata
        createdAt
        updatedAt
        Events {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const contactsByGroup = /* GraphQL */ `
  query ContactsByGroup(
    $groups: String
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactsByGroup(
      groups: $groups
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groups
        dncEmail
        ccid
        email
        name
        tags
        metadata
        createdAt
        updatedAt
        Events {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getBlast = /* GraphQL */ `
  query GetBlast($id: ID!) {
    getBlast(id: $id) {
      id
      groups
      status
      fromName
      fromEmail
      replyToName
      replyToEmail
      emailProgram
      titleCode
      isIMSight
      imsightCapturePII
      sendDateTime
      title
      shortDescription
      templateId
      sgBatchId
      templateVars
      Segments {
        id
        groups
        title
        description
        rules
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      Events {
        items {
          id
          groups
          contactID
          blastID
          eventType
          subType
          timestamp
          reason
          status
          response
          attempt
          asm_group_id
          useragent
          ip
          url
          imbAdr
          imbInHomeDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const listBlasts = /* GraphQL */ `
  query ListBlasts(
    $filter: ModelBlastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlasts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        status
        fromName
        fromEmail
        replyToName
        replyToEmail
        emailProgram
        titleCode
        isIMSight
        imsightCapturePII
        sendDateTime
        title
        shortDescription
        templateId
        sgBatchId
        templateVars
        Segments {
          id
          groups
          title
          description
          rules
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        Events {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
