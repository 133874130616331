import { API, graphqlOperation } from 'aws-amplify'
import React from 'react'
import { useHistory } from "react-router-dom"
import { createBlast } from '../graphql/mutations'
import { AuthContext } from '../helpers/AuthContext'
import useBlasts from '../hooks/useBlasts'

const Blasts = () => {
    const auth = React.useContext(AuthContext)
    const history = useHistory()
    const blasts = useBlasts()

    const createNewBlast = () => {
        async function addBlast(blast) {
            try {
                blast.groups = auth.group
                const gqlData = await API.graphql(graphqlOperation(createBlast, {
                    input: blast
                }))
                const id = gqlData.data.createBlast.id
                history.push(`/blasts/${id}`)
            } catch (err) {
                console.log('error creating blast:', err)
                alert('Error')
            }
        }
        // Add new project
        addBlast({
            title: 'Email Blast Title',
            shortDescription: 'Description of your email blast.',
            status: 'DRAFT'
        })
    }

    const createNewBlastButton = <button
        type="button"
        onClick={createNewBlast}
        className="px-4 mx-2 text-white transition duration-500 bg-red-500 border border-red-500 rounded-md select-none ease hover:bg-red-600 focus:outline-none focus:shadow-outline">
        <svg
            className="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
        </svg>
        <span className='sr-only'>New Blast</span>
    </button>

    const header = <header className="shadow bg-gray-50">
        <div className="items-center justify-between px-4 py-6 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between">
                <div>
                    <h1 className="text-3xl font-bold text-gray-900">Blasts</h1>
                </div>
                <div className="flex flex-row">
                    {createNewBlastButton}
                </div>
            </div>
        </div>
    </header>

    return (<>
        <div>
            {header}
            <main>
                <div className="py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">
                        <div className="overflow-y-auto border-b h-96">
                            <table className="w-full table-auto" >
                                <thead>
                                    <tr className="font-bold bg-gray-200">
                                        <td className='p-2'>Title</td>
                                        <td className='p-2'>Status</td>
                                        <td className='p-2'>Description</td>
                                        <td className='p-2'>Last Updated</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        blasts.map(({ id, title, status, shortDescription, updatedAt }) =>
                                            <tr key={id}
                                                onClick={() => { history.push(`/blasts/${id}`); }}
                                                className='even:bg-gray-100 hover:bg-yellow-100'
                                                style={{ cursor: 'cell' }}
                                            >
                                                <td className='p-1'>{title}</td>
                                                <td className='p-1'>{status}</td>
                                                <td className='p-1'>{shortDescription}</td>
                                                <td className='p-1'>{updatedAt}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </>)
}

export default Blasts