//import { useState, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { sendSendGridEmail } from '../graphql/mutations'
import { echo, getRecipientStats, getTemplate, getRecipientsForAudience } from '../graphql/queries'


const useSendGridSender = () => {

    const callEchoApi = async (message) => {
        //console.log('message', message)
        try {
            const gqlData = await API.graphql(graphqlOperation(echo, { msg: message }))
            return gqlData.data.echo
        } catch (err) {
            console.log('error calling echo', err)
            return err.message
        }
    }

    const sendEmail = async (blastId, audience, tags) => {
        //console.log('blastId', blastId)
        try {
            const gqlData = await API.graphql(graphqlOperation(sendSendGridEmail, {
                blastId,
                audience,
                tags
            }))
            return gqlData.data.sendSendGridEmail
        } catch (err) {
            console.log('error calling sendSendGridEmail', err)
            return err.message
        }
    }

    const callGetRecipientsForAudience = async (blastId, audience, tags) => {
        //console.log('blastId', blastId)
        try {
            const gqlData = await API.graphql(graphqlOperation(getRecipientsForAudience, {
                blastId,
                audience,
                tags
            }))
            return gqlData.data.getRecipientsForAudience
        } catch (err) {
            console.log('error calling getRecipientsForAudience', err)
            return err.message
        }
    }

    //Actually just calls recipientsForAudience with a segmentId
    const callGetRecipientsForSegment = async (segmentId, tags) => {
        try {
            const gqlData = await API.graphql(graphqlOperation(getRecipientsForAudience, {
                audience: "segment",
                segmentId,
                tags
            }))
            return gqlData.data.getRecipientsForAudience
        } catch (err) {
            console.log('error calling getRecipientsForAudience', err)
            return err.message
        }
    }

    const callGetRecipientStatsApi = async (blastId) => {
        try {
            const gqlData = await API.graphql(graphqlOperation(getRecipientStats, { blastId }))
            return gqlData.data.getRecipientStats ? JSON.parse(gqlData.data.getRecipientStats) : {}
        } catch (err) {
            console.log('error calling getRecipientStats', err)
            return err
        }
    }

    const callGetTemplateApi = async (templateId) => {
        //console.log('fetch template with templateId', templateId)
        try {
            const gqlData = await API.graphql(graphqlOperation(getTemplate, { templateId: templateId }))
            return gqlData.data.getTemplate ? JSON.parse(gqlData.data.getTemplate) : {}
        } catch (err) {
            console.log('error calling getTemplate', err)
            return {}
        }
    }

    return {
        callGetRecipientsForAudience,
        callGetRecipientsForSegment,
        callGetTemplateApi,
        sendEmail,
        callGetRecipientStatsApi,
        callEchoApi
    }
}

export default useSendGridSender