import { API, graphqlOperation } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { getBlast } from '../graphql/queries'

const useBlast = (blastId) => {
  const [item, setItem] = useState({})

  useEffect(async () => {
    if (null === blastId) return
    const fetch = async () => {
      try {
        //const gqlData = await API.graphql(graphqlOperation(getBlastWithFileAssets, { "id": blastId }))
        const gqlData = await API.graphql(graphqlOperation(getBlast, { "id": blastId }))
        const blast = gqlData.data.getBlast
        // blast.FileAssets.items = await Promise.all(
        //   blast.FileAssets.items.map(async item => {
        //     const { bucket, region, key } = item.file
        //     try {
        //       const url = await Storage.get(key, { bucket, region, level: 'private' })
        //       return { ...item, url }
        //     } catch (error) {
        //       console.log('error getting URL for FileAsset', error)
        //     }
        //   })
        // )
        blast && setItem(blast)
      } catch (err) {
        console.log(`error fetching blast ${blastId}`, err)
        alert('ERROR retrieving data from the database. (blast)')
      }
    }
    await fetch()
  }, [blastId])

  return item
}

export default useBlast