/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import useSendGridSender from '../../hooks/useSendGridSender'

//https://reactdatepicker.com/
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const BlastStepSchedule = ({ blast }) => {
    const { id: blastId, status, sendDateTime, batchId } = blast
    const [startDate, setStartDate] = useState(sendDateTime ? new Date(sendDateTime) : new Date());
    const [isOkToSend, setIsOkToSend] = useState(false)
    const { sendEmail } = useSendGridSender()
    console.log(`blastId`, blastId)
    console.log(`status`, status)
    // DRAFT
    // SCHEDULED
    // RECURRING
    // SENT
    // CLOSED

    const disableSend = (
        (null === blast.fromEmail) || (null === blast.replyToEmail) ||
        (null === blast.fromName) || (null === blast.replyToName) ||
        (null == blast.templateId))

    const message = () => {
        switch (status) {
            case 'DRAFT':
                return `Set the date you'd like to send this email.`
            case 'SCHEDULED':
                return `Email scheduled to be sent.`
            case 'RECURRING':
                return `This is a recurring email and will be sent automatically`
            case 'SENT':
                return `This email has already been sent.`
            case 'CLOSED':
                return `CLOSED`

            default:
                break;
        }
    }


    // useEffect(() => {
    //     // if startDate > NOW
    //     return () => {
    //         cleanup
    //     }
    // }, [input])


    const scheduleButtonClicked = () => {
        alert("schedule")
    }
    const backToDraftButtonClicked = () => {
        alert("set back to draft")
    }

    // const setScheduleButtonState = () => {
    //     if (!startDate) {
    //         setIsOkToSend(false)
    //         return
    //     }

    //     if ('DRAFT' !== status) {
    //         setIsOkToSend(false)
    //         return
    //     }


    //     setIsOkToSend(true)

    //     // If should be sent before the next CRON will run, send it now
    //     //let nextCron = new Date();
    //     //nextCron.setHours(nextCron.getHours() + 24);


    // }

    //useEffect(() => {
    //setScheduleButtonState()
    //}, [startDate, status, sendDateTime])

    const SendEmailNow = async () => {
        console.log('Send email now clicked.');
        let audience = 'oaklawn'
        let tags = []
        let result = await sendEmail(blastId, audience, tags)
        console.log('sendEmail:', result)
        alert(result)
    }

    const scheduleContainer = () => {
        let sdt = new Date(startDate)
        let now = new Date()
        if (sdt <= now) {
            console.log(`The send date must be in the future.`)
            //setIsOkToSend(false)
            //return
        }

        return <div className='p-4 prose'>
            <h3>Schedule your this blast</h3>
            <div className=''>
                <label className='block '>Date & time to send</label>
                <DatePicker
                    className="w-56 text-sm text-center"
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                    minDate={new Date()}
                    selected={startDate}
                    onChange={date => setStartDate(date)} />
            </div>
            {isOkToSend &&
                <div className='pb-6 pl-2 pr-2 mt-2 border border-red-400 bg-red-50'>
                    <p>If you schedule your email, it will be pushed to the ESP and sent at {new Date(startDate).toISOString()}.</p>
                    <button
                        onClick={scheduleButtonClicked}
                        className="ampyButton">SCHEDULE!</button>
                </div>
            }

            <div className='pb-6 pl-2 pr-2 mt-6 border border-yellow-400 bg-yellow-50'>
                <h3>Send this email immediately</h3>
                <button
                    type="button"
                    className="m-2 ampyButton"
                    disabled={disableSend} onClick={SendEmailNow}>SEND NOW!</button>
            </div>

            <div>
            </div>
        </div>
    }

    const scheduledContainer =
        <div className='p-4 prose'>
            <h3>This blast is scheduled!</h3>
            <div className='pb-6 pl-2 pr-2 mt-2 border border-red-400 bg-red-50'>
                <p>This blast will be pushed to the ESP and sent at {new Date(startDate).toISOString()}.</p>
                <button
                    onClick={backToDraftButtonClicked}
                    className="ampyButton">Back to DRAFT</button>
            </div>
            <button
                className="mt-4 ampySecondaryButton"
                onClick={SendEmailNow}>Send Now</button>
            <div>
            </div>
        </div >

    const cancelContainer =
        <div className='p-4 prose'>
            <h3>Blast processed by ESP, will be sent soon!</h3>
            <div className='pb-6 pl-2 pr-2 mt-2 border border-red-400 bg-red-50'>
                <p>This blast is at the ESP and will be sent at {new Date(startDate).toISOString()}.</p>
                <button
                    onClick={() => { alert('CANCEL BLAST') }}
                    className="ampyButton">Back to DRAFT</button>
            </div>
            <div>
            </div>
        </div>


    return (
        <div>
            <div className="flex flex-col bg-gray-100 h-96">
                {('DRAFT' === status) && scheduleContainer()}
                {('SCHEDULED' === status) && scheduledContainer}
                {(('SCHEDULED' === status) && (batchId)) && cancelContainer}
            </div>
        </div>
    )
}

BlastStepSchedule.propTypes = {
    blast: PropTypes.object
    // blastId: PropTypes.string,
    // status: PropTypes.string,
    // sendDateTime: PropTypes.string
}

export default BlastStepSchedule