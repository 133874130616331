import React, { useEffect, useReducer } from 'react'
import { Link } from 'react-router-dom'
import CSVImporter from '../components/Contact/CSVImporter'
import ToggleTagsContainer from '../components/ToggleTagsContainer'
import TagContext from '../hooks/useTagContext'
import useTags from '../hooks/useTags'

const ContactsImport = () => {
    const tags = useTags()
    const initialState = {
        tags: tags,
        colors: ['gray', 'green', 'red'],
        icons: [null, 'add', 'dont'],
        addTags: new Set(),
        removeTags: new Set()
    }

    const reducer = (state, action) => {
        //console.log('action', action)
        const nextState = { ...state }
        switch (action.type) {
            case 'TagsUpdated':
                nextState.tags = action.tags
                break
            case 'TagClick':
                switch (action.color) {
                    case 0: // ignore tag
                        nextState.addTags.delete(action.name)
                        nextState.removeTags.delete(action.name)
                        break
                    case 1: // add tag to contact
                        nextState.addTags.add(action.name)
                        nextState.removeTags.delete(action.name)
                        break
                    case 2: //remove tag from contact
                        nextState.addTags.delete(action.name)
                        nextState.removeTags.add(action.name)
                        break
                    default:
                        alert('dispatch error')
                        break
                }
                break
            default:
                break
        }
        return nextState
    }

    const [state, dispatch] = useReducer(reducer, initialState)
    useEffect(() => {
        dispatch({ type: 'TagsUpdated', tags: tags })
    }, [tags])

    const header = <header className="shadow bg-gray-50">
        <div className="px-4 py-6 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between ">
                <div>
                    <h1 className="text-3xl font-bold text-gray-900">Import / Update Contacts</h1>
                </div>
                <div>
                    <Link
                        type="button"
                        to='/contacts'
                        className="ampyButton">
                        <svg
                            className='inline-block w-6 h-6'
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg>
                    </Link>
                </div>
            </div>

        </div>
    </header>

    const main = <main>
        <div className="py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="px-4 py-6 sm:px-0">
                <TagContext.Provider value={{ state, dispatch }}>
                    <div className="p-2 mb-2 border bg-gray-50">
                        <p className="m-0.5">Assign tags to add/remove/ignore to contacts you import</p>
                        <ToggleTagsContainer />
                    </div>
                    <CSVImporter />
                </TagContext.Provider>
            </div>
        </div>
    </main>

    return <>
        {header}
        {main}
    </>
}

export default ContactsImport