import { API, graphqlOperation } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { listMetadataMappings } from '../graphql/queries'

const useMetadataMapping = () => {
    const [items, setItems] = useState([])

    useEffect(async () => {

        const fetch = async () => {
            console.log('FETCHING METADATA')
            try {
                const gqlData = await API.graphql(graphqlOperation(listMetadataMappings))
                const data = gqlData.data.listMetadataMappings
                setItems(data.items)
            } catch (err) {
                console.log('error fetching MetadataMapping items', err)
                alert('ERROR retrieving data from the database. (metadata mappings)')
            }
        }
        await fetch()
    }, [])

    return items
}

export default useMetadataMapping