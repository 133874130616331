import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { AmplifyAuthenticator, AmplifyContainer, AmplifySignIn, AmplifySignUp } from '@aws-amplify/ui-react'
import React, { useEffect, useState } from 'react'
import { BrowserRouter } from "react-router-dom"
import AppRoutes from "./AppRoutes"



const App = () => {
  const [authState, setAuthState] = useState()
  const [user, setUser] = useState()

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      //console.log('onAuthUIStateChange', nextAuthState, authData)
      setAuthState(nextAuthState)
      setUser(authData)
    })
  }, [])

  return authState === AuthState.SignedIn && user ? (
    <>
      <AppRoutes user={user} />
    </>
  ) : (
    <AmplifyContainer>
      <AmplifyAuthenticator usernameAlias="email">
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            { type: "email" },
            { type: "password" },
          ]}
        />
        <AmplifySignIn slot="sign-in" usernameAlias="email" />
      </AmplifyAuthenticator>
    </AmplifyContainer>
  )
}

//const AppWithAuth = withAuthenticator(App, false)
const AppWithRouter = () => <BrowserRouter><App /></BrowserRouter>
export default AppWithRouter