import PropTypes from 'prop-types'
import React, { useReducer } from 'react'
import BlastSteps from '../components/Blast/BlastSteps'
import BlastStepDesign from '../components/Blast/BlastStepDesign'
import BlastStepAddress from '../components/Blast/BlastStepAddress'
import BlastStepReports from '../components/Blast/BlastStepReports'
import BlastStepSchedule from '../components/Blast/BlastStepSchedule'
import useBlast from '../hooks/useBlast'

const MyContext = React.createContext()

const Blast = ({ blastId }) => {
    const blast = useBlast(blastId)
    const {
        title,
    } = blast
    //console.log(blast)

    const initialStepsStates = {
        design: true,
        address: false,
        schedule: false,
        report: false,
    }

    const stepsReducer = (step, action) => (
        {
            design: false,
            address: false,
            schedule: false,
            report: false,
            [action.which]: true
        }
    )

    const [stepsStates, dispatch] = useReducer(stepsReducer, initialStepsStates)
    //const [edit, setEdit] = useState(false)

    const blastComponent =
        <MyContext.Provider value={{ stepsStates, dispatch }}>
            <header className="shadow bg-gray-50 ">
                <div className="px-4 py-3 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between ">
                        <div>
                            <h1 className="relative text-3xl font-bold text-gray-900 ">{title}</h1>
                        </div>
                        <div>
                            <img className='relative top-0 right-0 w-16 h-16' src={`https://robohash.org/${blastId}`} />
                        </div>
                    </div>
                </div>
            </header>
            <main>
                <div className="py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">
                        <BlastSteps stepsStates={stepsStates} myContext={MyContext} />
                        {stepsStates.design && <BlastStepDesign blast={blast} />}
                        {stepsStates.address && <BlastStepAddress blast={blast} />}
                        {stepsStates.schedule && <BlastStepSchedule blast={blast} />}
                        {stepsStates.report && <BlastStepReports blast={blast} />}
                    </div>
                </div>
            </main>
        </MyContext.Provider>

    const blastNotFoundComponent =
        <>
            <header className="bg-white shadow">
                <div className="px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold text-gray-900">
                        Oops, blast not found.
                    </h1>
                </div>
            </header>
            <main>
                <div className="py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    {/* <!-- Replace with your content --> */}
                    <div className="px-4 py-6 sm:px-0">
                        <div className="flex items-center justify-center border-4 border-gray-200 border-dashed rounded-lg h-96">
                            <p className='font-semibold text-gray-900'>Blast not found.</p>
                        </div>
                    </div>
                    {/* <!-- /End replace --> */}
                </div>
            </main>
        </>

    return <>
        {/* TODO: state should be: loading -> error or blast, avoid 'not found' screen flash */}
        {blast?.title ? blastComponent : blastNotFoundComponent}
    </>

}

Blast.propTypes = {
    blastId: PropTypes.any
}

export default Blast