const Papa = require('papaparse')

const objectToCSV = (og) => {
  return Papa.unparse(og, { header: true })
  // const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
  // const header = Object.keys(og[0])
  // const csv = [
  //     header.join(','), // header row first
  //     ...og.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
  // ].join('\r\n')
  // return csv
}

export default objectToCSV