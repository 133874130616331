import React, { useReducer, useState } from 'react'
import { Link } from 'react-router-dom'
import Message from '../components/Message'
import ContactsTable from '../components/Contact/ContactsTable'
import ToggleTagsContainer from '../components/ToggleTagsContainer'
import downloadData from '../helpers/downloadData'
import useContacts from '../hooks/useContacts'
import TagContext from '../hooks/useTagContext'
import useTags from '../hooks/useTags'

const Contacts = () => {
    const tags = useTags()
    const [nextPage, setNextPage] = useState(null)
    const initialState = {
        tags: tags,
        colors: ['gray', 'green'],
        icons: [null, null],
        addTags: new Set(),
        removeTags: new Set()
    }

    const reducer = (state, action) => {
        //console.log('action', action)
        const nextState = { ...state }
        switch (action.type) {
            case 'TagsUpdated':
                nextState.tags = action.tags
                break
            case 'TagClick':
                switch (action.color) {
                    case 0: // ignore tag
                        nextState.addTags.delete(action.name)
                        nextState.removeTags.delete(action.name)
                        setNextPage(null)
                        break
                    case 1: // add tag to contact
                        nextState.addTags.add(action.name)
                        nextState.removeTags.delete(action.name)
                        setNextPage(null)
                        break
                    case 2: //remove tag from contact
                        nextState.addTags.delete(action.name)
                        nextState.removeTags.add(action.name)
                        setNextPage(null)
                        break
                    default:
                        alert('dispatch error')
                        break
                }
                break
            default:
                break
        }
        return nextState
    }
    const [state, dispatch] = useReducer(reducer, initialState)
    const { contacts, nextToken, loading, error } = useContacts(state.addTags, nextPage)

    const downloadContactsJSON = () => {
        if (nextToken) {
            alert("report is missing data")
        }
        const data = contacts.map(contact => {
            delete contact.id
            delete contact.Events
            const o = {
                ...JSON.parse(contact.metadata),
                ...contact,
            }
            delete o.metadata
            return o
        })
        const jsonData = JSON.stringify(data)
        downloadData(jsonData, `text/json`, `contacts.json`)
    }

    const objectToCSV = (og) => {
        const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
        const header = Object.keys(og[0])
        const csv = [
            header.join(','), // header row first
            ...og.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
        ].join('\r\n')
        return csv
    }

    const downloadContactsCSV = () => {
        if (nextToken) {
            alert("report is missing data")
        }
        const data = contacts.map(contact => {
            delete contact.id
            delete contact.Events
            const o = {
                ...JSON.parse(contact.metadata),
                ...contact,
            }
            delete o.metadata
            return o
        })
        downloadData(objectToCSV(data), `text/csv`, `contacts.csv`)
    }

    const tagsDirty = tags.toString() + tags.length
    React.useEffect(() => {
        console.log(`Contacts useEffect called`)
        dispatch({ type: 'TagsUpdated', tags: tags })
        return () => {
            //cleanup
        }
    }, [tagsDirty])

    const loadMoreContent = () => {
        setNextPage(nextToken)
    }



    const contactsErrorsMessageBox = (
        (error?.length > 0) &&
        <Message
            negative
            icon='warning sign'
            header='Unable to retrieve contacts'
            content={error}
        />
    )
    const importContactsButton = <Link
        to='/import-contacts'
        type="button"
        className="ampyButton">
        <svg
            className="inline-block w-6 h-6"
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
        </svg>
        <span className="sr-only">import contacts</span>
    </Link>

    const exportContactsJSONButton = <button
        type="button"
        onClick={downloadContactsJSON}
        className="ampyButton">
        <svg
            className="inline-block w-6 h-6"
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <span>export JSON</span>
    </button>

    const exportContactsCSVButton = <button
        type="button"
        onClick={downloadContactsCSV}
        className="ampyButton">
        <svg
            className="inline-block w-6 h-6"
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <span>export CSV</span>
    </button>

    const header = <header className="shadow bg-gray-50">
        <div className="px-4 py-6 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between ">
                <div>
                    <h1 className="text-3xl font-bold text-gray-900">Contacts</h1>
                </div>
                <div>
                    {exportContactsJSONButton}
                    {exportContactsCSVButton}
                    {importContactsButton}
                </div>
            </div>

        </div>
    </header>

    const main = <main>
        <div className="py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="px-4 py-6 sm:px-0">
                <div>
                    {contactsErrorsMessageBox}
                </div>
                <TagContext.Provider value={{ state, dispatch, tagsDirty }}>
                    <div className="p-2 mb-2 border bg-gray-50">
                        <p className="m-0.5">Show contacts with any of these tags:</p>
                        <ToggleTagsContainer />
                    </div>
                    <ContactsTable
                        contacts={contacts}
                        loading={loading}
                        nextToken={nextToken}
                        error={error}
                        loadMoreContent={loadMoreContent}
                    />
                </TagContext.Provider>
            </div>
        </div>
    </main>

    return <>
        {header}
        {main}
    </>
}

export default Contacts