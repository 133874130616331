import Papa from 'papaparse'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Loader from '../components/Loader'
import StyledContainer from '../components/StyledContainer'

function useCSVStyledDropzone(percentCompleted) {
    const [parseResults, setParseResults] = useState()
    const [isParsing, setIsParsing] = useState(false)

    useEffect(() => {
        if (100 === percentCompleted) {
            setParseResults(undefined)
            setIsParsing(false)
            //setContactsToUpload([])
        }
        return () => {
            //cleanup
        }
    }, [percentCompleted])

    const onDrop = useCallback(acceptedFiles => {
        setIsParsing(acceptedFiles.length > 0)
        acceptedFiles.forEach(file => {
            const config = {
                worker: true,
                header: true,
                skipEmptyLines: "greedy",
                complete: function (results, file) {
                    setIsParsing(false)
                    console.log("Parsing complete:", results, file)
                    results.meta.fields.forEach(field => field !== field.trim() && alert(`UNSUPPORTED! Header field "${field}" has extra whitespace. FIX and re-import.`))

                    //key = idx + prev.data.length  --- fix for multiple file parsing support
                    results.data = results.data.map((r, idx) => {
                        Object.keys(r).forEach(k => {
                            if (r[k] === 'NULL') {
                                r[k] = ''
                            }
                        })
                        return ({ 'ampymailRowKey': idx, ...r })
                    })

                    //results.data = results.data.map((r, idx) => ({ 'ampymailRowKey': idx, ...r }))
                    //results.data = results.data.map(r => ({ ...r, 'EMAIL': `${r.CUSTOMER_ID}@codetemporary.com` }))
                    setParseResults(prev => {
                        if (undefined === prev) {
                            return results
                        } else {
                            const data = [...prev.data, ...results.data]
                            const errors = [...prev.errors, ...results.errors]
                            return {
                                data,
                                errors,
                                meta: prev.meta
                            }
                        }
                    })
                }
            }
            Papa.parse(file, config)
        })
    }, [])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ onDrop, accept: 'text/csv' })

    const svgCloudUpload = <svg
        className="w-8 h-8"
        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
    </svg>

    const dropzoneContainer =
        <StyledContainer {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
            <input {...getInputProps()} />
            <div className='flex flex-col items-center'>
                {svgCloudUpload}
                {isDragAccept ? <span>Drop it like it&rsquo;s hot</span> : 'DROP CSV HERE'}
            </div>
            <Loader active={isParsing} />
        </StyledContainer>

    return { isParsing, parseResults, setParseResults, dropzoneContainer }
}

export default useCSVStyledDropzone
