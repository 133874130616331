import PropTypes from 'prop-types'
import React from 'react'

const Page = (props) => {
    return (
        <>
            {props.children}
        </>
    )
}
Page.propTypes = {
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    children: PropTypes.any
}

export default Page