import PropTypes from 'prop-types'
import React from 'react'
import TagContext from '../hooks/useTagContext'

const ToggleTag = ({ tagName, tagColor }) => {
    const { state, dispatch } = React.useContext(TagContext)
    const { colors, icons } = state
    const [color, setColor] = React.useState(tagColor)

    /*
        do not purge these colors
            ring-gray-300
            bg-gray-300
            bg-gray-500
            ring-green-300
            bg-green-300
            bg-green-500
    */

    const onTagClick = (name) => {
        let newColor = ((color + 1) % colors.length)
        setColor(newColor)
        dispatch({ type: 'TagClick', name, color: newColor })
    }
    return (
        <button
            onClick={() => {
                onTagClick(tagName, icons)
            }}
            className={`inline-flex items-center justify-center h-6 px-4 m-1 font-medium text-sm text-gray-50 transition-colors duration-150 rounded-lg focus:shadow-outline focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-${colors[color]}-300  bg-${colors[color]}-300 hover:bg-${colors[color]}-500`}>{tagName}</button>

        // <a className="py-0.5 px-2 mr-2 font-sans font-normal text-sm shadow-md rounded-md inline-block align-top text-gray-50 bg-gray-500" style={{ backgroundColor: colors[color] }} onClick={() => {
        //     onTagClick(tagName)
        // }}>{(null != icons[color]) && <span>{icons[color]} </span>}{tagName}</a>
    )
}

ToggleTag.propTypes = {
    tagName: PropTypes.string,
    tagColor: PropTypes.number,
}

export default ToggleTag
