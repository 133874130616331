import PropTypes from 'prop-types'
import React from 'react'
import SegmentRules from '../components/Segment/SegmentRules'
import useSegment from '../hooks/useSegment'
import useSendGridSender from '../hooks/useSendGridSender'

const Segment = ({ segmentId }) => {
    const segment = useSegment(segmentId)
    const { callGetRecipientsForSegment } = useSendGridSender()
    console.log(`segmentId`, segmentId)
    console.log(`segment`, segment)

    const getSegmentRecipients = async () => {
        let result = await callGetRecipientsForSegment(segmentId, [])
        console.log(`result`, result)
        const recipients = JSON.parse(result)
        console.log('GetEmailRecipients:', recipients)
        alert(`Segment matches ${recipients.length} recipients`)
    }

    const segmentComponent = segment?.title && <>
        <div>
            <header className="bg-white shadow">
                <div className="px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold text-gray-900">
                        {segment.title}
                    </h1>
                </div>
            </header>
            <main>
                <div className="py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <button
                        type="button"
                        className="ampyButton"
                        onClick={getSegmentRecipients}>Get Segment Recipients</button>
                    <div className="px-4 py-6 sm:px-0">
                        <div className="border-4 border-gray-200 border-dashed rounded-lg">
                            <SegmentRules rules={JSON.parse(segment.rules)} />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </>
    const segmentNotFoundComponent =
        <>
            <header className="bg-white shadow">
                <div className="px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold text-gray-900">
                        Oops, segment not found.
            </h1>
                </div>
            </header>
            <main>
                <div className="py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">
                        <div className="flex items-center justify-center border-4 border-gray-200 border-dashed rounded-lg h-96">
                            <p className='font-semibold text-gray-900'>Segment not found.</p>
                        </div>
                    </div>
                </div>
            </main>
        </>

    {/* TODO: state should be: loading -> error or segment, avoid 'not found' screen flash */ }
    return segment?.title ? segmentComponent : segmentNotFoundComponent
}

Segment.propTypes = {
    segmentId: PropTypes.string
}
export default Segment