import React, { useEffect, useReducer, useState } from 'react'
//import PropTypes from 'prop-types'
import ContactsTable from '../Contact/ContactsTable'
import CSVImporter from '../Contact/CSVImporter'
import useContacts from '../../hooks/useContacts'
import TagContext from '../../hooks/useTagContext'
import useTags from '../../hooks/useTags'

const BlastStepAddressTesters = (/*{ blast }*/) => {
    const tags = useTags()
    const [nextPage, setNextPage] = useState(null)

    const loadMoreContent = () => {
        setNextPage(nextToken)
    }

    const initialState = {
        tags: tags,
        colors: [null, 'green'],
        icons: [null, null],
        addTags: new Set().add('test'),
        removeTags: new Set()
    }

    const reducer = (state, action) => {
        //console.log('action', action)
        const nextState = { ...state }
        switch (action.type) {
            case 'TagsUpdated':
                nextState.tags = action.tags
                break
            default:
                break
        }
        return nextState
    }
    const [state, dispatch] = useReducer(reducer, initialState)
    const { contacts: testers, nextToken, loading, error } = useContacts(state.addTags, nextPage)

    useEffect(() => {
        dispatch({ type: 'TagsUpdated', tags: tags })
        return () => {
            //cleanup
        }
    }, [tags])

    const svgAtIcon = <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="at" className="w-6 h-6 svg-inline--fa fa-at fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C118.941 8 8 118.919 8 256c0 137.059 110.919 248 248 248 48.154 0 95.342-14.14 135.408-40.223 12.005-7.815 14.625-24.288 5.552-35.372l-10.177-12.433c-7.671-9.371-21.179-11.667-31.373-5.129C325.92 429.757 291.314 440 256 440c-101.458 0-184-82.542-184-184S154.542 72 256 72c100.139 0 184 57.619 184 160 0 38.786-21.093 79.742-58.17 83.693-17.349-.454-16.91-12.857-13.476-30.024l23.433-121.11C394.653 149.75 383.308 136 368.225 136h-44.981a13.518 13.518 0 0 0-13.432 11.993l-.01.092c-14.697-17.901-40.448-21.775-59.971-21.775-74.58 0-137.831 62.234-137.831 151.46 0 65.303 36.785 105.87 96 105.87 26.984 0 57.369-15.637 74.991-38.333 9.522 34.104 40.613 34.103 70.71 34.103C462.609 379.41 504 307.798 504 232 504 95.653 394.023 8 256 8zm-21.68 304.43c-22.249 0-36.07-15.623-36.07-40.771 0-44.993 30.779-72.729 58.63-72.729 22.292 0 35.601 15.241 35.601 40.77 0 45.061-33.875 72.73-58.161 72.73z"></path></svg>

    const testersTab = <div className='min-w-full prose'>
        <h2>{svgAtIcon} Test Users</h2>
        <container>
            <TagContext.Provider value={{ state, dispatch }}>
                <ContactsTable
                    contacts={testers}
                    loading={loading}
                    nextToken={nextToken}
                    error={error}
                    loadMoreContent={loadMoreContent}
                />
                <div className='mt-4 mb-4'>
                    <span>The <span className='pill'>test</span> tag will be assigned to all test contacts</span>
                </div>
                <CSVImporter />
            </TagContext.Provider>
        </container>
    </div>

    return (
        <>
            {testersTab}
        </>
    )
}

BlastStepAddressTesters.propTypes = {
    //blast: PropTypes.any
}

export default BlastStepAddressTesters