import { API, graphqlOperation } from 'aws-amplify'
import { useEffect, useState } from 'react'
//import sha256, { Hex } from 'crypto-js/sha256'
import { getContact } from '../graphql/queries'

const useContact = (contactId) => {
    const [item, setItem] = useState({})
    useEffect(async () => {
        if (null === contactId) return
        const fetch = async () => {
            try {
                const gqlData = await API.graphql(graphqlOperation(getContact, { "id": contactId }))
                const contact = gqlData.data.getContact
                setItem(contact)
            } catch (err) {
                console.log(`error fetching contact ${contactId}`, err)
                alert('ERROR retrieving data from the database. (contact)')
            }
        }
        await fetch()
    }, [contactId])

    return item
}

export default useContact