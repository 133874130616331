import { API, graphqlOperation } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { getSegment } from '../graphql/queries'

const useSegment = (segmentId) => {
    const [item, setItem] = useState({})

    useEffect(async () => {
        if (null === segmentId) return
        const fetch = async () => {
            try {
                const gqlData = await API.graphql(graphqlOperation(getSegment, { "id": segmentId }))
                const segment = gqlData.data.getSegment
                segment && setItem(segment)
            } catch (err) {
                console.log(`error fetching segment ${segmentId}`, err)
                alert('ERROR retrieving data from the database. (segment)')
            }
        }
        await fetch()
    }, [segmentId])

    return item
}

export default useSegment