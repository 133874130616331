import { API, graphqlOperation } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { listTags } from '../graphql/queries'


const useTags = () => {
    const [items, setItems] = useState([])
    useEffect(async () => {
        try {
            console.log('fetching tags');
            const gqlData = await API.graphql(graphqlOperation(listTags))
            const tags = gqlData.data.listTags.items.map(tag => tag.name)
            setItems(tags)
        } catch (err) {
            console.log('error fetching Tags', err)
            alert('ERROR retrieving data from the database. (tags)')
        }
    }, [])

    return items
}

// const useTags = () => {
//     return ['boo', 'gov', 'spy', 'marketing', 'customer']
// }
export default useTags