import styled from 'styled-components'

const getBorderColor = (props) => {
    if (props.isDragAccept) {
        return '#2f472f'//'#00e676'
    }
    if (props.isDragReject) {
        return '#ff1744'
    }
    if (props.isDragActive) {
        return '#ffff00' //'#2196f3'
    }
    return '#eeeeee'
}

const getBackgroundColor = (props) => {
    if (props.isDragAccept) {
        return '#b9deb8'
    }
    if (props.isDragReject) {
        return '#deb8c0'
    }
    if (props.isDragActive) {
        return '#000000'
    }
    return '#fafafa'
}

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#2f472f' //'#00e676'
    }
    if (props.isDragReject) {
        return '#ff1744'
    }
    if (props.isDragActive) {
        return '#bdbdbd'
    }
    return '#bdbdbd'
}


const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 6px;
  border-radius: 6px;
  border-color: ${props => getBorderColor(props)};
  border-style: dashed;
  background-color: ${props => getBackgroundColor(props)};
  color: ${props => getColor(props)};
  outline: none;
  transition: border .18s ease-in-out;
`

export default StyledContainer
