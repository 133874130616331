const badDomains = new Set([
    'mailinator.com',
    'trashmail.com',
])

// These are email prefixes we do not want to email
// Entries must be LOWERCASE
// good list https://support.omnisend.com/en/articles/1061861-compliance-the-role-based-email-addresses
// another good liset https://connect.act-on.com/hc/en-us/articles/360023757134-Generic-and-Role-based-Email-Addresses
const badEmails = new Set([
    'all',
    'abuse',
    'accounting',
    'admin',
    'administrator',
    'admissions',
    'ads',
    'all',
    'answers',
    'anti-spam',
    'antispam',
    'asdf',
    'auctions',
    'billing',
    'compliance',
    'competition',
    'contact',
    'contactus',
    'devnull',
    'dns',
    'dns',
    'events',
    'email',
    'everyone',
    'feedback',
    'finance',
    'ftp',
    'help',
    'hostmaster',
    'info',
    'inoc',
    'inquiries',
    'investorrelations',
    'ir',
    'ispfeedback',
    'ispsupport',
    'jobs',
    'law',
    'leads',
    'list',
    'list-request',
    'listadmin',
    'maildaemon',
    'marketing',
    'media',
    'news',
    'nobody',
    'noc',
    'no-reply',
    'noreply',
    'donotreply',
    'do-not-reply',
    'nomail',
    'noemail',
    'null',
    'office',
    'phish',
    'phishing',
    'pr',
    'press',
    'privacy',
    'postmaster',
    'purchasing',
    'recipients',
    'registrar',
    'remove',
    'root',
    'sales',
    'service',
    'security',
    'serviceadmin',
    'spam',
    'subscribe',
    'support',
    'sysadmin',
    'tech',
    'team',
    'undisclosed-recipients',
    'unsubscribe',
    'usenet',
    'uucp',
    'webmaster',
    'www'])

export { badEmails, badDomains }
