/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:7450ba83-66ac-435e-9617-c73adba929e9",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_J00tz95TQ",
    "aws_user_pools_web_client_id": "4hshu2hfaanfc3b3soh1lartba",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://mhczxik5m5hyfahbdu2qmydoz4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-7hmgsslydrfcfl3jv2vrneg5ku"
};


export default awsmobile;
