import PropTypes from 'prop-types'
import React from 'react'

const Step = ({ active, onClick, ...props }) => {
    return <>
        <button
            onClick={onClick}
            className={`w-1/3 p-1 rounded-lg text-center ${active ? 'bg-red-300' : 'bg-none'} focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-600`}>
            <div className="flex items-center justify-center bg-gray-300 border border-gray-200 rounded-lg">
                {props.children}
            </div>
        </button>
    </>
}
Step.propTypes = {
    onClick: PropTypes.func,
    active: PropTypes.bool,
    children: PropTypes.any
}

const designStep =
    <>
        <div className="flex items-center justify-center w-1/3 h-20 bg-transparent icon-step">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="paint-brush" className="w-6 h-6 svg-inline--fa fa-paint-brush fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M167.02 309.34c-40.12 2.58-76.53 17.86-97.19 72.3-2.35 6.21-8 9.98-14.59 9.98-11.11 0-45.46-27.67-55.25-34.35C0 439.62 37.93 512 128 512c75.86 0 128-43.77 128-120.19 0-3.11-.65-6.08-.97-9.13l-88.01-73.34zM457.89 0c-15.16 0-29.37 6.71-40.21 16.45C213.27 199.05 192 203.34 192 257.09c0 13.7 3.25 26.76 8.73 38.7l63.82 53.18c7.21 1.8 14.64 3.03 22.39 3.03 62.11 0 98.11-45.47 211.16-256.46 7.38-14.35 13.9-29.85 13.9-45.99C512 20.64 486 0 457.89 0z"></path></svg>
        </div>
        <div className="flex flex-col items-center justify-center w-2/3 h-24 px-1 bg-gray-200 rounded-r-lg body-step">
            <h2 className="text-sm font-bold">Design</h2>
            <p className="text-xs text-gray-600">Tune and tweak until you&rsquo;re happy</p>
        </div>
    </>

const addressStep =
    <>
        <div className="flex items-center justify-center w-1/3 h-20 bg-transparent icon-step">
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M24 22h-24v-20h24v20zm-1-19h-22v18h22v-18zm-4 13v1h-4v-1h4zm-6.002 1h-10.997l-.001-.914c-.004-1.05-.007-2.136 1.711-2.533.789-.182 1.753-.404 1.892-.709.048-.108-.04-.301-.098-.407-1.103-2.036-1.305-3.838-.567-5.078.514-.863 1.448-1.359 2.562-1.359 1.105 0 2.033.488 2.545 1.339.737 1.224.542 3.033-.548 5.095-.057.106-.144.301-.095.41.14.305 1.118.531 1.83.696 1.779.41 1.773 1.503 1.767 2.56l-.001.9zm-9.998-1h8.999c.003-1.014-.055-1.27-.936-1.473-1.171-.27-2.226-.514-2.57-1.267-.174-.381-.134-.816.119-1.294.921-1.739 1.125-3.199.576-4.111-.332-.551-.931-.855-1.688-.855-.764 0-1.369.31-1.703.871-.542.91-.328 2.401.587 4.09.259.476.303.912.13 1.295-.342.757-1.387.997-2.493 1.252-.966.222-1.022.478-1.021 1.492zm18-3v1h-6v-1h6zm0-3v1h-6v-1h6zm0-3v1h-6v-1h6z" /></svg>
        </div>
        <div className="flex flex-col items-center justify-center w-2/3 h-24 px-1 bg-gray-200 rounded-r-lg body-step">
            <h2 className="text-sm font-bold">Adddress</h2>
            <p className="text-xs text-gray-600">Assign contacts</p>
        </div>
    </>

const scheduleStep =
    <>
        <div className="flex items-center justify-center w-1/3 h-20 bg-transparent icon-step">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="paper-plane" className="w-6 h-6 svg-inline--fa fa-paper-plane fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M440 6.5L24 246.4c-34.4 19.9-31.1 70.8 5.7 85.9L144 379.6V464c0 46.4 59.2 65.5 86.6 28.6l43.8-59.1 111.9 46.2c5.9 2.4 12.1 3.6 18.3 3.6 8.2 0 16.3-2.1 23.6-6.2 12.8-7.2 21.6-20 23.9-34.5l59.4-387.2c6.1-40.1-36.9-68.8-71.5-48.9zM192 464v-64.6l36.6 15.1L192 464zm212.6-28.7l-153.8-63.5L391 169.5c10.7-15.5-9.5-33.5-23.7-21.2L155.8 332.6 48 288 464 48l-59.4 387.3z"></path></svg>
        </div>
        <div className="flex flex-col items-center justify-center w-2/3 h-24 px-1 bg-gray-200 rounded-r-lg body-step">
            <h2 className="text-sm font-bold">Schedule</h2>
            <p className="text-xs text-gray-600">Schedule and send!</p>
        </div>
    </>

const reportStep =
    <>
        <div className="flex items-center justify-center w-1/3 h-20 bg-transparent icon-step">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chart-line" className="w-6 h-6 svg-inline--fa fa-chart-line fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM464 96H345.94c-21.38 0-32.09 25.85-16.97 40.97l32.4 32.4L288 242.75l-73.37-73.37c-12.5-12.5-32.76-12.5-45.25 0l-68.69 68.69c-6.25 6.25-6.25 16.38 0 22.63l22.62 22.62c6.25 6.25 16.38 6.25 22.63 0L192 237.25l73.37 73.37c12.5 12.5 32.76 12.5 45.25 0l96-96 32.4 32.4c15.12 15.12 40.97 4.41 40.97-16.97V112c.01-8.84-7.15-16-15.99-16z"></path></svg>
        </div>
        <div className="flex flex-col items-center justify-center w-2/3 h-24 px-1 bg-gray-200 rounded-r-lg body-step">
            <h2 className="text-sm font-bold">Reports</h2>
            <p className="text-xs text-gray-600">Campaign stats and reports</p>
        </div>
    </>

const stepArrow = <div className="flex items-center justify-center flex-1 m-4">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" /></svg>
</div>

const BlastSteps = ({ myContext }) => {
    const { stepsStates, dispatch } = React.useContext(myContext)
    return (
        <div className='mb-6'>
            {/* <h1 className="mb-4 font-black text-center text-gray-700">STEPS</h1> */}
            <div className="flex">
                <Step onClick={() => { dispatch({ which: 'design' }) }} active={stepsStates.design}>
                    {designStep}
                </Step>
                {stepArrow}
                <Step onClick={() => { dispatch({ which: 'address' }) }} active={stepsStates.address} >
                    {addressStep}
                </Step>
                {stepArrow}
                <Step onClick={() => { dispatch({ which: 'schedule' }) }} active={stepsStates.schedule} >
                    {scheduleStep}
                </Step>
                {stepArrow}
                <Step onClick={() => { dispatch({ which: 'report' }) }} active={stepsStates.report} >
                    {reportStep}
                </Step>
            </div>
        </div>
    )
}

BlastSteps.propTypes = {
    myContext: PropTypes.object,
    props: PropTypes.any,
}

export default BlastSteps