/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import parse from 'date-fns/parse'
import formatISO from 'date-fns/formatISO'
import sha256, { Hex } from 'crypto-js/sha256'
import { difference, union } from 'lodash'
import { createContact, updateContact, createMetadataMapping } from '../graphql/mutations'
import { AuthContext } from '../helpers/AuthContext'

const useContactsBatchUploader = (groups, addTags, removeTags, metadataMapping) => {
    const [uploadResults, setUploadResults] = useState([])
    const [contactsToUpload, setContactsToUpload] = useState([])
    const [percentUploaded, setPercentUploaded] = useState(-1)
    const auth = useContext(AuthContext)

    /*
        query BatchGetContacts(
            $id_0: ID!
            $id_1: ID!
            $id_2: ID!
        ) {
            getContact_0: getContact(id: $id_0) {id tags metadata}
            getContact_1: getContact(id: $id_1) {id tags metadata}
            getContact_2: getContact(id: $id_2) {id tags metadata}
        }
        {
        "id_0": "769a67b6020c0d37dcf939d3a7968a5207b7f8d3c7697c0c769f4fed21d8bfea",
        "id_1": "7698f689dd9ad29c65110542c41cc10657e2a91f467538c0bf824fd10c6ecf21",
        "id_2": "74c6a55a8170a13ec80fca968c50c737ce1cdb22364fbc8308b31a8af2d04ab0"
        }
    */
    const batchGetContacts = async (contacts) => {
        const INPUTS = contacts.map((e, i) => `  $id_${i}: ID!`).join('\n')
        const GETS = contacts.map((e, i) => `  getContact_${i}: getContact(id: $id_${i}) {id tags metadata}`).join('\n')
        const batchQuery = /* GraphQL */ `
query BatchGetContacts(
` + `${INPUTS}` + `
) {
` + `${GETS}` + `
}`
        const inputs = {}
        contacts.forEach((e, i) => {
            inputs[`id_${i}`] = e.id
        })
        const gqlData = await API.graphql(graphqlOperation(batchQuery, inputs))
        return gqlData.data
    }

    const addOrUpdateContacts = async (contacts, addTags, removeTags) => {
        const foundContactsData = await batchGetContacts(contacts)
        const inputs = {}
        const INPUTS = []
        const MUTATES = []

        contacts.forEach((c, i) => {
            const contactData = foundContactsData[`getContact_${i}`]

            if (null === contactData) {
                c.metadata = JSON.stringify(c.metadata)
                c.tags = [...addTags]
                inputs[`input_${i}`] = c
                INPUTS.push(`  $input_${i}: CreateContactInput!`)
                MUTATES.push(`  mutation_${i}: createContact(input: $input_${i}, condition: $condition) {id}`)
            }
            else if (c.id === contactData.id) {
                const cloudMeta = JSON.parse(contactData.metadata)
                const localMeta = c.metadata
                const mergedMeta = {
                    ...cloudMeta,
                    ...localMeta
                }
                c.metadata = JSON.stringify(mergedMeta)
                let tags = contactData.tags
                c.tags = difference(tags, [...removeTags])
                c.tags = union(c.tags, [...addTags])
                inputs[`input_${i}`] = c
                INPUTS.push(`  $input_${i}: UpdateContactInput!`)
                MUTATES.push(`  mutation_${i}: updateContact(input: $input_${i}, condition: $condition) {id}`)
            } else {
                console.log('unexpected')
            }
        })

        const batchMutation = /* GraphQL */ `
mutation MutateContacts(
` + `${INPUTS.join('\n')}` + `
$condition:
ModelContactConditionInput) {
` + `${MUTATES.join('\n')}` + `
}`

        const gqlData = await API.graphql(graphqlOperation(batchMutation, inputs))
        if (gqlData.errors) console.log(`Match mutate errors:`, gqlData.errors)
        //console.log(`gqlData.data`, gqlData.data)
        return Object.keys(gqlData.data).length
    }

    /*
      Pull email out of object, put rest of object into metadata property
      converts: (key:value, key:value, email:address, key:value)
      to: {email:address, metadata:{key:value, key:value}}
    */
    const restructure = (brand, contactsData, metadataMapping) => {
        const uMessages = [`Importing/updating ${contactsData.length} records`]

        // which user columns are the reserved fields? (undefined if not found)
        const keys = Object.keys(metadataMapping)
        const cidColumn = keys.find(key => metadataMapping[key] === 'CONTACT_ID')
        const emailColumn = keys.find(key => metadataMapping[key] === 'EMAIL')
        const nameColumn = keys.find(key => metadataMapping[key] === 'NAME')
        const firstColumn = keys.find(key => metadataMapping[key] === 'FIRST_NAME')
        const lastColumn = keys.find(key => metadataMapping[key] === 'LAST_NAME')
        const dncEmailColumn = keys.find(key => metadataMapping[key] === 'DNC_EMAIL')

        if ((undefined === cidColumn) && (undefined === emailColumn)) {
            throw new Error('import requires either CONTACT_ID or EMAIL')
        }

        const safeGet = (csvContact, key) => {
            const value = key ? csvContact[key] : ''
            return value ? value.trim() : ''
        }

        const getId = (idx, brand, csvContact, cidColumn, emailColumn) => {
            const rowNum = idx + 2
            if (undefined !== cidColumn) {
                // Use user provided data for the ID
                const id = safeGet(csvContact, cidColumn)
                if (!id) {
                    uMessages.push(`Not importing row ${rowNum} due to missing CONTACT_ID.`)
                    return undefined
                }
                return sha256((brand + id).toLowerCase()).toString(Hex)
                //return ((brand + id).toLowerCase())
            } else {
                // Compose our own ID using brand+email
                const email = safeGet(csvContact, emailColumn)
                if (!email) {
                    uMessages.push(`Not importing row ${rowNum} due to missing EMAIL address.`)
                    return undefined
                }
                return sha256((brand + email).toLowerCase()).toString(Hex)
            }
        }

        const getCcId = (idx, brand, csvContact, cidColumn) => {
            if (undefined !== cidColumn) {
                return safeGet(csvContact, cidColumn)
            }
        }

        const getEmail = (idx, csvContact, emailColumn) => {
            const rowNum = idx + 2
            const email = safeGet(csvContact, emailColumn)
            if (!email) {
                uMessages.push(`Not importing row ${rowNum} due to missing EMAIL address.`)
                return undefined
            }
            return email
        }

        const getDncEmail = (idx, csvContact, column) => {
            let value = safeGet(csvContact, column)
            if (undefined === value) return null
            value = value.trim().toLowerCase()
            if (0 === value.length) return null
            switch (value) {
                case "0":
                case "no":
                case "false":
                    return null
                default:
                    return "No Email"
            }
        }

        const compositeName = (rowNum, csvContact, firstColumn, lastColumn) => {
            const fname = safeGet(csvContact, firstColumn)
            const lname = safeGet(csvContact, lastColumn)
            const name = `${fname} ${lname}`.trim()
            if (!name) {
                uMessages.push(`Row ${rowNum} will be missing the NAME field.`)
            }
            return name ? name : null
        }

        const getName = (idx, csvContact, nameColumn, firstColumn, lastColumn) => {
            const rowNum = idx + 2
            // use the user provided name column
            let name = safeGet(csvContact, nameColumn)
            if (name) {
                return name
            }
            // No name, attempt to composite name
            return compositeName(rowNum, csvContact, firstColumn, lastColumn)
        }

        const remapMetadata = (idx, csvContact, metadataMapping) => {
            const metadata = {}
            Object.keys(metadataMapping).forEach((key) => {
                switch (key) {
                    case emailColumn:
                    case nameColumn:
                    case dncEmailColumn:
                    case cidColumn: // <-- MOVE below to keep user's cid column in metadata
                        return // skip, don't add to metadata

                    case firstColumn:
                    case lastColumn:
                        metadata[key] = csvContact[key].trim()
                        return
                }
                if (metadataMapping[key] === "DO NOT IMPORT") {
                    return // ignore
                }

                // fix Oaklawn dates
                switch (key) {
                    case 'recentPlayDate':
                    case 'Media_StartDate1':
                    case 'Media_EndDate1':
                    case 'Media_StartDate2':
                    case 'Media_EndDate2':
                    case 'Media_StartDate3':
                    case 'Media_EndDate3':
                    case 'Media_StartDate4':
                    case 'Media_EndDate4':
                    case 'Media_StartDate5':
                    case 'Media_EndDate5':
                    case 'Media_StartDate6':
                    case 'Media_EndDate6':
                    case 'Media_StartDate7':
                    case 'Media_EndDate7':
                    case 'Media_StartDate8':
                    case 'Media_EndDate8':
                    case 'Media_StartDate9':
                    case 'Media_EndDate9':
                    case 'Media_StartDate10':
                    case 'Media_EndDate10':
                    case 'Media_StartDate11':
                    case 'Media_EndDate11':
                    case 'Media_StartDate12':
                    case 'Media_EndDate12':
                        {
                            const wackyDate = csvContact[key]
                            if (wackyDate) {
                                try {
                                    const fixedDate = parse(wackyDate, 'M/d/yy', new Date())
                                    csvContact[key] = formatISO(fixedDate, { representation: 'date' })
                                    //csvContact[key] = new Date(fixedDate).toISOString()
                                } catch (error) {
                                    console.log(`Fix DATE error`, error)
                                    console.log(`DATE was: `, wackyDate)
                                }
                            }
                            break
                        }
                    default:
                        break
                }

                //console.log(`metadataMapping key  :`, key)
                //console.log(`metadataMapping value:`, metadataMapping[key])

                switch (metadataMapping[key]) {
                    case "METADATA_TEXT":
                    case "METADATA_DATE":
                    case "METADATA_NUMBER":
                    case "METADATA_BOOLEAN":
                        // Save using the 'key' name.
                        // Only hits this code for a new field
                        metadata[key] = csvContact[key].trim()
                        return
                }

                // For new header in the CSV remapped to an existing field:
                // key = name of header in the CSV (e.g. "SomeNewField")
                // metadataMapping[key] = name of saved header user wants to store as (e.g. offerId1)
                metadata[metadataMapping[key]] = csvContact[key].trim()
            })
            return metadata ? metadata : null
        }

        const dataToUpload = contactsData.map((csvContact, idx) => {
            const contact = {
                id: getId(idx, brand, csvContact, cidColumn, emailColumn),
                groups: brand,
                email: getEmail(idx, csvContact, emailColumn),
                dncEmail: getDncEmail(idx, csvContact, dncEmailColumn),
                name: getName(idx, csvContact, nameColumn, firstColumn, lastColumn),
                metadata: remapMetadata(idx, csvContact, metadataMapping),
            }

            const ccid = getCcId(idx, brand, csvContact, cidColumn)
            if (ccid) {
                contact.ccid = ccid
                //delete contact.metadata[cidColumn]
            }
            //console.log('contact', contact)
            return (contact.id && contact.email) ? contact : {}
        })
        setUploadResults((prev) => {
            return [...prev, ...uMessages]
        })
        //console.log('dataToUpload', JSON.stringify(dataToUpload, null, 2))
        return dataToUpload
    }

    const addMetadataFieldsToDatabase = async (fields) => {
        const isMetadata = (type) => {
            switch (type) {
                case 'METADATA_TEXT':
                case 'METADATA_NUMBER':
                case 'METADATA_DATE':
                case 'METADATA_BOOLEAN':
                    return true
                default:
                    return false
            }
        }
        const keys = Object.keys(fields)
        for (let index = 0; index < keys.length; index++) {
            const key = keys[index]
            if (!isMetadata(fields[key])) {
                continue // we've already added this header to the DB
            }
            const input = {
                id: auth.group + '_' + key,
                fieldname: key,
                type: fields[key],
                groups: auth.group
            }
            try {
                const gqlData = await API.graphql(graphqlOperation(
                    createMetadataMapping, { input }
                ))
                const data = gqlData.data.createMetadataMapping
                console.log(`createMetadataMapping data`, data)
            } catch (err) {
                console.log(`metadata mapping input`, input)
                console.log('error creating metadatamapping:', err)
                //alert('DB create new field error, check logs.')
            }
        }
    }

    const importContactsThrottled = async (groups, contactsData, addTags, removeTags, metadataMapping) => {
        if (contactsData.length > 0) console.log(`IMPORTING ${contactsData.length} CONTACTS`, contactsData)
        setPercentUploaded(0)
        setUploadResults([])

        await addMetadataFieldsToDatabase(metadataMapping)

        let contacts = restructure(groups, contactsData, metadataMapping)
        //const UPDATE_MS = 1000 // update UI if processing is slow
        const SLEEP_OVERHEAD_MS = 2
        //const MAX_PER_SECOND = 500 // NOTE: There are 2 requests per modify (get & update) AWS rate limit 1000/s
        const total = contacts.length
        const BATCH_SIZE = 250

        let totalContacts = 0
        while (contacts.length) {
            let sentCount = 0
            let startT = Date.now()
            // Fire all the events we're allowed within 1 second
            // or until we've processed for UPDATE_MS
            // or until we run out of events
            const batch = contacts.splice(0, BATCH_SIZE)
            const recordsUpdated = await addOrUpdateContacts(batch, addTags, removeTags)
            totalContacts += recordsUpdated
            //console.log(`added ${recordsUpdated}`)

            // do {
            //     let contact = contacts.shift()
            //     const data = await addOrUpdateContact(contact, addTags, removeTags)
            //     data && contactIDs.push(data.id)
            // } while (contacts.length && (sentCount++ < MAX_PER_SECOND) && ((Date.now() - startT) < UPDATE_MS))
            let percent = Math.round(100 - (100 * (contacts.length / total)))
            setPercentUploaded(percent)

            // Sleep until the next second
            let elapsedT = (Date.now() - startT)
            let sleepMS = 1000 - elapsedT
            if ((sleepMS > SLEEP_OVERHEAD_MS) && (sentCount < contacts.length)) {
                console.log('sleeping:', sleepMS)
                await new Promise(r => setTimeout(r, sleepMS))
            }
        }
        //setContactsToUpload([])
        console.log('IMPORTING CONTACTS FINISHED')
        console.log('IMPORTED/UPDATED # contacts:', totalContacts)
        setUploadResults((prev) => {
            return [...prev, `Completed.`]
        })
    }

    useEffect(async () => {
        if (contactsToUpload.length === 0) return
        if (Object.keys(metadataMapping).length === 0) return
        await importContactsThrottled(groups, contactsToUpload, addTags, removeTags, metadataMapping)
        setContactsToUpload([])
        setPercentUploaded(-1)
        return () => {
            //cleanup
            console.log("USE-CONTACTS-UPLOADER-UNMOUNTED")
        }
    }, [contactsToUpload, addTags, removeTags, metadataMapping])

    return [setContactsToUpload, percentUploaded, uploadResults]

}

export default useContactsBatchUploader