import { API, graphqlOperation } from 'aws-amplify'
//import { useEffect, useState } from 'react'

const listEvents = /* GraphQL */ `
    query ListEvents(
      $filter: ModelEventFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          contactID
          eventType
          subType
          timestamp
          reason
          status
          response
          attempt
          asm_group_id
          useragent
          ip
          url
          Contact {
              items {
                  ccid
                  name
                  email
              }
          }
        }
        nextToken
      }
    }
  `

const useEvents = () => {
    // const [items, setItems] = useState([])
    // const [loading, setLoading] = useState(true)
    // const [error, setError] = useState(null)

    const buildFilter = (blastId) => {
        return {
            blastID: {
                eq: blastId
            }
        }
    }

    const fetchAllEvents = async (blastId) => {
        if ((null === blastId) || (undefined === blastId)) {
            return undefined
        }
        // setLoading(true)
        // setError(null)
        let variables = {
            filter: buildFilter(blastId),
            limit: 1000,
        }
        try {
            let gqlData
            let resultItems = []
            let nextToken
            do {
                variables.nextToken = nextToken
                gqlData = await API.graphql(graphqlOperation(listEvents, variables))
                resultItems = [...resultItems, ...gqlData.data.listEvents.items]
                nextToken = gqlData.data.listEvents.nextToken
                console.log(`Fetched ${gqlData.data.listEvents.items.length} events`)
            } while (nextToken)
            //setItems(resultItems)
            return resultItems
        } catch (err) {
            console.log('error fetching events', err)
            return undefined
            //setError('ERROR: ' + err?.errors ? err.errors[0].message : err.message)
        }
        //setLoading(false)
    }

    // useEffect(async () => {
    //     setItems([])
    // }, [blastId])

    // useEffect(async () => {
    //     await fetchEvents(blastId)
    //     return () => {
    //         //cleanup
    //     }
    // }, [blastId])

    //return { events: items, loading, error }
    return { fetchAllEvents }
}

export default useEvents