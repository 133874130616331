import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API, graphqlOperation } from 'aws-amplify'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Message from '../../components/Message'
import { listMxRecords } from '../../graphql/queries'
import { addMxRecords } from '../../graphql/mutations'
import alignColumnsToDataTypes from '../../helpers/alignColumnsToDataTypes'
import objectToCSV from '../../helpers/objectToCSV'
import downloadData from '../../helpers/downloadData'
import { AuthContext } from '../../helpers/AuthContext'
//import useContactsUploader from '../../hooks/useContactsUploader'
import useContactsUploader from '../../hooks/useContactsBatchUploader'
import useCSVStyledDropzone from '../../hooks/useCSVStyledDropzone'
import useMetadataMapping from '../../hooks/useMetadataMapping'
import TagContext from '../../hooks/useTagContext'
import { badDomains, badEmails } from '../../helpers/badEmails'
import BaseTable, { Column, AutoResizer } from 'react-base-table'
import 'react-base-table/styles.css'

function CSVImporter() {
  const auth = useContext(AuthContext)

  const { state } = useContext(TagContext)
  const { addTags, removeTags } = state
  const [metaStates, setMetaStates] = useState({})
  const [setContactsToUpload, percentCompleted, uploadResults] = useContactsUploader(auth.group, addTags, removeTags, metaStates)
  const { parseResults, dropzoneContainer } = useCSVStyledDropzone(percentCompleted)
  const [validationErrors, setValidationErrors] = useState([])
  const [validationWarnings, setValidationWarnings] = useState([])
  const [okToImport, setOkToImport] = useState(false)
  const contactsMetadata = useMetadataMapping()
  const [showAddDomainsButton, setShowAddDomainsButton] = useState(false)
  const [validationPct, setValidationPct] = useState(-1)
  const [suppressDuplicateEmailWarning, setSuppressDuplicateEmailWarning] = useState(false)
  const [suppressMissingEmailWarning, setSuppressMissingEmailWarning] = useState(false)
  const [suppressSpammyEmailWarning, setSuppressSpammyEmailWarning] = useState(false)
  const [excludeDuplicateEmails, setExcludeDuplicateEmails] = useState(false)
  const [excludeErrors, setExcludeErrors] = useState(false)
  const [excludeWarnings, setExcludeWarnings] = useState(false)
  const [excludeMailserverUnreachableErrors, setExcludeMailserverUnreachableErrors] = useState(false)
  const [excludeBadEmails, setExcludeBadEmails] = useState(false)
  const [excludeSpammyEmails, setExcludeSpammyEmails] = useState(false)
  const [exportAppendBrandColumn, setExportAppendBrandColumn] = useState(false)

  const metaAlignmentOptions = [
    { text: 'NEW FIELD (text)', value: 'METADATA_TEXT', icon: 'add', key: 'newtxt' },
    { text: 'NEW FIELD (number)', value: 'METADATA_NUMBER', icon: 'add', key: 'newnum' },
    { text: 'NEW FIELD (date)', value: 'METADATA_DATE', icon: 'add', key: 'newdate' },
    { text: 'NEW FIELD (boolean)', value: 'METADATA_BOOLEAN', icon: 'add', key: 'newboolean' },
    { text: 'DO NOT IMPORT', value: 'DO NOT IMPORT', icon: 'ban', key: 'ban' },
    { text: 'CONTACT_ID', value: 'CONTACT_ID', icon: 'barcode', key: 'contact' },
    { text: 'EMAIL', value: 'EMAIL', icon: 'mail', key: 'email' },
    { text: 'NAME', value: 'NAME', icon: 'user', key: 'user' },
    { text: 'FIRST_NAME', value: 'FIRST_NAME', icon: 'linkify', key: 'first' },
    { text: 'LAST_NAME', value: 'LAST_NAME', icon: 'linkify', key: 'last' },
    { text: 'DNC Email', value: 'DNC_EMAIL', icon: 'ban', key: 'noEmail' },
    ...contactsMetadata.sort((a, b) => a.fieldname.toLowerCase().localeCompare(b.fieldname.toLowerCase())).map((meta, idx) => ({ text: meta.fieldname, value: meta.fieldname, type: meta.type, key: idx }))
  ]
  const csvHeadings = parseResults ? parseResults.meta.fields : []

  const resetState = () => {
    setMetaStates({})
    setValidationErrors([])
    setValidationWarnings([])
    setOkToImport(false)
  }

  useEffect(() => {
    if (undefined === parseResults) return
    resetState()
    setMetaStates(alignColumnsToDataTypes(csvHeadings, contactsMetadata))
  }, [parseResults])

  useEffect(() => {
    if (undefined === parseResults) return
    getWarningsAndErrorsBasedOnColumnTypeAssignments()
  }, [metaStates])

  const getColumnAssignmentCount = (serverColumn) => {
    return csvHeadings.filter(localColumn => {
      return metaStates[localColumn] === serverColumn
    }).length
  }

  const getWarningsAndErrorsBasedOnColumnTypeAssignments = () => {
    // Return TRUE if serverColumn is used at least once

    const idCount = getColumnAssignmentCount('CONTACT_ID')
    const emailCount = getColumnAssignmentCount('EMAIL')
    const nameCount = getColumnAssignmentCount('NAME')
    const firstCount = getColumnAssignmentCount('FIRST_NAME')
    const lastCount = getColumnAssignmentCount('LAST_NAME')
    //const dncEmailCount = getColumnAssignmentCount('DNC_EMAIL')

    let vErrors = []
    let vWarnings = []

    // EMAIL
    if (0 === emailCount) {
      if (0 === idCount) {
        vErrors.push(`Please indicate which column contains EMAIL addresses. OR indicate which column contains the CONTACT_ID if you're only updating existing contacts.`)
      } else {
        vWarnings.push(`The EMAIL column is missing. This is ONLY ok if you are updating existing contacts. Any new contact will be missing an email address!`)
      }
    }

    // CONTACT_ID
    if (0 === idCount) {
      vWarnings.push(`If you do not provide the CONTACT_ID, we will derive one using the contact's email address.`)
    }

    // NAME
    if ((0 === nameCount) && (0 === firstCount) && (0 === lastCount)) {
      vWarnings.push(`If you do not indicate the column containing the NAME part of the email address (or FIRST_NAME + LAST_NAME columns) new contacts will not have a NAME field.`)
    } else if ((0 !== nameCount) && ((0 !== firstCount) || (0 !== lastCount))) {
      vWarnings.push(`Import will only use FIRST_NAME and/or LAST_NAME fields when the NAME column is empty.`)
    }

    // ALL FIELDS should only be mapped once (excluding 'new' or 'ignored' fields)
    const serverColumns = metaAlignmentOptions.map(o => o.value)
    serverColumns.forEach(serverColumn => {
      if (('DO NOT IMPORT' !== serverColumn) && (!serverColumn.startsWith('METADATA_')) && (1 < getColumnAssignmentCount(serverColumn))) {
        vErrors.push(`Only 1 column can be designated as ${serverColumn}.`)
      }
    })

    setValidationErrors(vErrors)
    setValidationWarnings(vWarnings)
  }

  // const getHeadingForMapping = (serverColumn) => {
  //     return csvHeadings.find(localColumn => metaStates[localColumn] === serverColumn)
  // }

  const getColorForColumn = (metadataField) => {
    switch (metadataField) {
      case 'DO NOT IMPORT':
        return '#a83632'
      case 'METADATA_TEXT':
      case 'METADATA_DATE':
      case 'METADATA_NUMBER':
      case 'METADATA_BOOLEAN':
        return '#a8a430'
      case 'EMAIL':
      case 'NAME':
      case 'CONTACT_ID':
      case 'FIRST_NAME':
      case 'LAST_NAME':
      case 'DNC_EMAIL':
        return '#32a852'
      default:
        return '#00ffff'
    }
  }

  const progressBar = percentComplete =>
    <div className="bg-red-200">
      <div className="mb-2 bg-gray-200 shadow" >
        <div
          className="py-2 text-xs leading-none text-center text-white bg-green-700"
          style={{ width: `${percentComplete}%` }}>
          {percentComplete}%
            </div>
      </div>
    </div>

  const uploadResultsMessageBox = (
    (uploadResults.length > 0) && ((undefined === parseResults && okToImport)) &&
    <Message
      info
      icon='cloud upload'
      header='Import Results'
      content={uploadResults.map((e, idx) => <span style={{ display: 'block' }} key={idx}>{e}</span>)}
    />
  )

  const validationColumns = [
    {
      key: 'column',
      title: 'column',
      dataKey: 'column',
      width: 90,
      align: Column.Alignment.LEFT,
      sortable: false,
    },
    {
      key: 'row',
      title: 'row',
      dataKey: 'row',
      width: 75,
      align: Column.Alignment.LEFT,
      sortable: false,
    },
    {
      key: 'value',
      title: 'value',
      dataKey: 'value',
      width: 250,
      align: Column.Alignment.LEFT,
      sortable: false,
    },
    {
      key: 'type',
      title: 'type',
      dataKey: 'type',
      width: 75,
      align: Column.Alignment.LEFT,
      sortable: false,
    },
    {
      key: 'subtype',
      title: 'subtype',
      dataKey: 'subtype',
      width: 100,
      align: Column.Alignment.LEFT,
      sortable: false,
    },
    {
      key: 'note',
      title: 'note',
      dataKey: 'note',
      width: 500,
      align: Column.Alignment.LEFT,
      sortable: false,
    },
  ]

  const validationErrorsTable =
    ("string" === typeof validationErrors[0])
      ? validationErrors.map((e, idx) => <span style={{ display: 'block' }} key={idx}>{e}</span>)
      : <div className='mt-4 h-72 ErrorsTable'>
        <AutoResizer>
          {({ width, height }) => (
            <div>
              <BaseTable
                className='bg-red-900'
                columns={validationColumns}
                fixed
                data={validationErrors.map((w, i) => ({ id: i, ...w }))}
                rowKey='id'
                width={width}
                height={height}
                rowHeight={32}
                rowClassName='bg-red-100 border-red-800'
                headerClassName='bg-red-100 border-red-800'
              />
            </div>
          )}
        </AutoResizer>
      </div>

  const importErrorsMessageBox = (
    (validationErrors.length > 0) &&
    <Message
      negative
      icon='warning sign'
      header='Please correct this issue before importing contacts.'
      content={validationErrorsTable}
    />
  )

  const validationWarningsTable =
    ("string" === typeof validationWarnings[0])
      ? validationWarnings.map((e, idx) => <span style={{ display: 'block' }} key={idx}>{e}</span>)
      : <div className='mt-4 h-72 WarningsTable'>
        <AutoResizer>
          {({ width, height }) => (
            <div>
              <BaseTable
                className='bg-yellow-900'
                columns={validationColumns}
                fixed
                data={validationWarnings.map((w, i) => ({ id: i, ...w }))}
                rowKey='id'
                width={width}
                height={height - (showAddDomainsButton ? 40 : 0)}
                rowHeight={32}
                rowClassName='bg-yellow-100 border-yellow-800'
                headerClassName='bg-yellow-100 border-yellow-800'
              />
              {showAddDomainsButton && <button
                className="w-40 px-4 py-1 m-2 text-white transition duration-500 bg-green-500 border border-green-500 rounded-md select-none ease hover:bg-green-600 focus:outline-none focus:shadow-outline"
                onClick={async () => await addNewMxRecords()}>Add Mailservers</button>}
            </div>
          )}
        </AutoResizer>
      </div>

  const importWarningsMessageBox = (
    (validationWarnings.length > 0) &&
    <Message
      warning
      icon='bullhorn'
      header='Please carefully review the following before importing:'
      content={validationWarningsTable}
    />
  )

  const importContactsMessageBox = (
    (validationErrors.length === 0) &&
    <Message
      positive
      icon='cloud upload'
      header='Add/update contacts'
      content={<>
        <div className='mt-2'>
          {!okToImport && <div className='flex'>
            <div className=''>
              <button
                className="px-4 py-1 m-2 text-white transition duration-500 bg-green-500 border border-green-500 rounded-md select-none ease hover:bg-green-600 focus:outline-none focus:shadow-outline"
                onClick={async () => await validateBeforeImport({
                  suppressDuplicateEmailWarning,
                  suppressMissingEmailWarning,
                  suppressSpammyEmailWarning,
                  excludeErrors,
                  excludeWarnings
                })}>Validate</button>
            </div>
            <div className='flex flex-col ml-3'>
              <p><strong>Validation Options</strong></p>
              <div>{/* Suppress missing email warnings checkbox */}
                <input type="checkbox"
                  id="suppressMissingEmailWarning"
                  name="suppressMissingEmailWarning"
                  value={suppressMissingEmailWarning}
                  onChange={
                    () => { setSuppressMissingEmailWarning(p => !p) }
                  }
                />
                <label htmlFor="suppressMissingEmailWarning"> Suppress missing email warnings</label>
              </div>
              <div>{/* Suppress duplicate email warnings checkbox */}
                <input type="checkbox"
                  id="suppressDuplicateEmailWarning"
                  name="suppressDuplicateEmailWarning"
                  value={suppressDuplicateEmailWarning}
                  onChange={
                    () => { setSuppressDuplicateEmailWarning(p => !p) }
                  }
                />
                <label htmlFor="suppressDuplicateEmailWarning"> Suppress duplicate email warnings</label>
              </div>
              <div>{/* Suppress spammy email warnings checkbox */}
                <input type="checkbox"
                  id="suppressSpammyEmailWarning"
                  name="suppressSpammyEmailWarning"
                  value={suppressSpammyEmailWarning}
                  onChange={
                    () => { setSuppressSpammyEmailWarning(p => !p) }
                  }
                />
                <label htmlFor="suppressSpammyEmailWarning"> Suppress spammy email warnings</label>
              </div>
            </div>
            <div className='flex flex-col ml-3'>
              <p><strong>Export Options</strong></p>
              <div>{/* Export Brand Append checkbox */}
                <input type="checkbox"
                  id="exportAppendBrandColumn"
                  name="exportAppendBrandColumn"
                  value={exportAppendBrandColumn}
                  onChange={
                    () => { setExportAppendBrandColumn(p => !p) }
                  }
                />
                <label htmlFor="exportAppendBrandColumn"> Append {auth.group} column </label>
              </div>
              <div>{/* Exclude DuplicateEmails checkbox */}
                <input type="checkbox"
                  id="excludeDuplicateEmails"
                  name="excludeDuplicateEmails"
                  value={excludeDuplicateEmails}
                  onChange={
                    () => { setExcludeDuplicateEmails(p => !p) }
                  }
                />
                <label htmlFor="excludeDuplicateEmails"> Exclude duplicate emails</label>
              </div>
              <div>{/* Exclude Mailserver Unreachable Errors checkbox */}
                <input type="checkbox"
                  id="excludeMailserverUnreachableErrors"
                  name="excludeMailserverUnreachableErrors"
                  value={excludeMailserverUnreachableErrors}
                  onChange={
                    () => { setExcludeMailserverUnreachableErrors(p => !p) }
                  }
                />
                <label htmlFor="excludeMailserverUnreachableErrors"> Exclude contacts with Mailserver Unreachable Errors</label>
              </div>
              <div>{/* Exclude Bad Emails checkbox */}
                <input type="checkbox"
                  id="excludeBadEmails"
                  name="excludeBadEmails"
                  value={excludeBadEmails}
                  onChange={
                    () => { setExcludeBadEmails(p => !p) }
                  }
                />
                <label htmlFor="excludeBadEmails"> Exclude contacts with an invalid email address</label>
              </div>
              <div>{/* Exclude Spammy Emails checkbox */}
                <input type="checkbox"
                  id="excludeSpammyEmails"
                  name="excludeSpammyEmails"
                  value={excludeSpammyEmails}
                  onChange={
                    () => { setExcludeSpammyEmails(p => !p) }
                  }
                />
                <label htmlFor="excludeSpammyEmails"> Exclude contacts with a group email address</label>
              </div>
              <div>{/* Exclude Errors checkbox */}
                <input type="checkbox"
                  id="excludeErrors"
                  name="excludeErrors"
                  value={excludeErrors}
                  onChange={
                    () => { setExcludeErrors(p => !p) }
                  }
                />
                <label htmlFor="excludeErrors"> Export contacts omitting rows with an error</label>
              </div>

              <div>{/* Exclude Warnings checkbox */}
                <input type="checkbox"
                  id="excludeWarnings"
                  name="excludeWarnings"
                  value={excludeWarnings}
                  onChange={
                    () => { setExcludeWarnings(p => !p) }
                  }
                />
                <label htmlFor="excludeWarnings"> Export contacts omitting rows with a warning</label>
              </div>

            </div>
          </div>}

          {(okToImport && (validationWarnings.length === 0)) && <p>Data appears valid.{(0 !== getColumnAssignmentCount('DO NOT IMPORT')) && <span> Some columns will not be imported.</span>}</p>}
          {(okToImport && (validationWarnings.length !== 0)) && <p>You should correct validation warnings and refresh this page before importing.</p>}
          {okToImport && <button
            className="px-4 py-1 m-2 text-white transition duration-500 bg-green-500 border border-green-500 rounded-md select-none ease hover:bg-green-600 focus:outline-none focus:shadow-outline"
            onClick={() => {
              setContactsToUpload(parseResults.data)
            }}>Import</button>}
          {(-1 !== percentCompleted) && progressBar(percentCompleted)}
          {(-1 !== validationPct) && progressBar(validationPct)}
        </div>
      </>} />
  )

  const howItWorksSection =
    <div className='mt-4 prose'>
      <Link to="/help/contacts-import-help">How to import contacts <FontAwesomeIcon icon={faQuestionCircle} /></Link>
      <h2>How it works</h2>
      <div>
        <ul>
          <li>Drag a &ldquo;comma separated values&rdquo; (.CSV) spreadsheet of contacts into the drop area.</li>
          <li>We&lsquo;ll let you know how many contacts we find.</li>
          <li>If a contact already exists, it will be updated. If you don&rsquo;t want certain data updated, mark it as DO NOT IMPORT.</li>
          <li>We have some protections in place, but you should strive to only import clean data.</li>
        </ul>
        <table>
          <tbody>
            <tr><td className='font-bold'>CONTACT_ID</td><td>We assign an ID to each contact. We will use your ID values if you provide them. If IDs are not unique, records will be mergered in unpredictable ways. If you do not provide an ID field, we will use the email address (which naturally helps with de-duping).</td></tr>
            <tr><td className='font-bold'>EMAIL</td><td>Each contact MUST have a unique email address. If the email address is not unique, some actions by one contact will affect the other contact. (e.g. unsubscribe).</td></tr>
            <tr><td className='font-bold'>NAME</td><td>The NAME field is the human readable element of the email address.</td></tr>
            <tr><td className='font-bold'>FIRST_NAME</td><td>We can composite FIRST_NAME and LAST_NAME fields together to create the NAME field.</td></tr>
            <tr><td className='font-bold'>LAST_NAME</td><td>Same as FIRST_NAME. If you do not want to use FIRST_NAME and/or LAST_NAME as the contact&rsquo; email name, but DO want to use them in dynamic data, then do not set them to the reserved import terms.</td></tr>
            <tr><td className='font-bold'>DNC_EMAIL</td><td>Any value other than 0, no, or false will mark this contact as do not contact by email.</td></tr>
            <tr><td className='font-bold'>custom</td><td>Other columns will be imported so you can use them as {"{{dynamic data}}"} in your communications. Do not import data you don&lsquo;t intend to use. You can always add it later.</td></tr>
          </tbody>
        </table>
      </div>
    </div>

  const columns = csvHeadings.map(c => (
    {
      key: c,
      title: c,
      dataKey: c,
      width: 192,
      align: Column.Alignment.LEFT,
      sortable: false,
      // eslint-disable-next-line react/display-name, react/prop-types
      cellRenderer: ({ cellData }) => <div>{cellData}</div>,
      bgColor: metaStates[c]
    }
  ))

  const data = useMemo(
    () => parseResults ? parseResults.data : [],
    [parseResults]
  )

  const headerRenderer = ({ columns }) => {
    return columns.map(column =>
      <div key={column.key}
        className='border-r border-gray-500'
        style={{ width: column.width, background: getColorForColumn(column.bgColor) }}>
        <div className='flex flex-col p-2'>
          <select
            value={metaStates[column.title]}
            onChange={(event) => {
              setOkToImport(false)
              setMetaStates(p => {
                let state = { ...p }
                state[column.title] = event.target.value
                return state
              })
            }}>
            {metaAlignmentOptions.map(o => <option key={o.key} value={o.value}>{o.text}</option>)}
          </select>
          <span className='mt-2 mb-2 text-lg'>{column.title}</span>
        </div>
      </div>
    )
  }

  const contactsTable =
    <div className='ContactsTable h-96'>
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            fixed
            columns={columns}
            data={data}
            rowKey='ampymailRowKey'
            width={width}
            height={height}
            rowHeight={40}
            headerHeight={102}
            headerRenderer={headerRenderer}
          />
        )}
      </AutoResizer>
    </div>

  const addNewMxRecords = async () => {
    try {
      const domains = new Set()
      parseResults.data.map(contact => {
        csvHeadings.forEach(c => {
          if ('EMAIL' === metaStates[c]) {
            const email = contact[c]?.trim().toLowerCase()
            const domain = email.slice(email.lastIndexOf('@') + 1, email.length)
            var isDomain = /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/
            if (isDomain.test(domain)) {
              domains.add(domain)
            }
          }
        }
        )
      })
      const gqlData = await API.graphql(graphqlOperation(addMxRecords, { "mxRecords": Array.from(domains) }))
      const json = JSON.parse(gqlData.data.addMxRecords)
      const result = json?.success
      setShowAddDomainsButton(false)
      alert(`Adding mailservers: ${result}`)
    } catch (error) {
      console.log(`addMxRecords error`, error)
      alert(error.errors[0].message)
    }
  }

  const getAllMxRecords = async () => {
    let variables = {
      limit: 1000,
    }
    try {
      let gqlData
      let resultItems = []
      let nextToken
      do {
        variables.nextToken = nextToken
        gqlData = await API.graphql(graphqlOperation(listMxRecords, variables))
        resultItems = [...resultItems, ...gqlData.data.listMxRecords.items]
        nextToken = gqlData.data.listMxRecords.nextToken
        console.log(`Fetched ${gqlData.data.listMxRecords.items.length} Mx records`)
      } while (nextToken)
      return resultItems
    } catch (err) {
      console.log('error fetching Mx records', err)
      alert('Error getting mailservers.')
      return []
    }
  }

  const handleValidationResults = ({ foundNewDomains, errors, warnings, rows }) => {
    setShowAddDomainsButton(foundNewDomains)
    setOkToImport((0 === errors.length) && !foundNewDomains)
    warnings && setValidationWarnings(warnings)
    errors && setValidationErrors(errors)

    // Export errors & warnings
    if ((errors.length > 0) || (warnings.length > 0)) {
      downloadData(
        objectToCSV([...errors, ...warnings]),
        'text/plain',
        `validation_results.csv`)
    }

    const shouldCleanData = (
      exportAppendBrandColumn
      || excludeDuplicateEmails
      || excludeMailserverUnreachableErrors
      || excludeBadEmails
      || excludeSpammyEmails
      || excludeWarnings
      || excludeErrors)
    if (shouldCleanData) {
      const dataToExport = rows.map(r => {
        const o = { ...r }
        if (exportAppendBrandColumn && "BrandOne" === auth.group) {
          // There are many duplicate CONTACTID and EMAIL records (even after combining them)
          // And if someone receives printed materials with a certain address on it, we want
          // them to be able to print those exact materials.
          // Also, there are 3 files and some emails are duplicated across them too, so just
          // to be sure contacts aren't de-dupped across files; we add the role code.
          // This string should be unique within a month; but hopefully be the same month-to-month
          // for the same contact so the DB doesn't grow and grow.
          o.contact_id = `${o.C_EMAIL}/${o.C_CONTACTID}/${o.C_ROLE_CODE}/${o.C_MAILING_STREET}`.toLowerCase()
        }
        delete o.ampymailRowKey
        return o
      })
      // omit rows with certain errors
      errors.forEach(e => {
        const rowNum = e.row - 2 // array[0] = file[2]
        if (excludeErrors) {
          dataToExport[rowNum] = null
          return
        }
        if (excludeMailserverUnreachableErrors && ("E120" === e.code)) {
          dataToExport[rowNum] = null
          return
        }
        if (excludeBadEmails && ("E110" === e.code)) {
          dataToExport[rowNum] = null
          return
        }
      })

      // omit rows with certain warnings
      warnings.forEach(w => {
        const rowNum = w.row
        if (excludeWarnings) {
          dataToExport[rowNum - 2] = null
          return
        }
        if (excludeBadEmails
          && (("W100" === w.code) || ("W110" === w.code))) {
          dataToExport[rowNum - 2] = null
          return
        }
        if (excludeDuplicateEmails && ("W160" === w.code)) {
          dataToExport[rowNum - 2] = null
          return
        }
        if (excludeSpammyEmails && ("W120" === w.code)) {
          dataToExport[rowNum - 2] = null
          return
        }
      })

      const cleanedRows = dataToExport.filter(r => null != r)
      if (cleanedRows?.length > 0) {
        downloadData(
          objectToCSV(cleanedRows),
          'text/csv',
          `data.csv`)
      } else {
        alert('No valid data after cleaning.')
      }
    }
  }

  const validateBeforeImport = async () => {

    const allMxRecords = await getAllMxRecords()

    const worker = new Worker('contactValidateWorker.js')
    worker.postMessage({
      allMxRecords,
      badDomains,
      badEmails,
      rows: parseResults.data,
      metaStates,
      csvHeadings,
      metaAlignmentOptions,
      suppressDuplicateEmailWarning,
      suppressMissingEmailWarning,
      suppressSpammyEmailWarning
    })
    worker.onmessage = e => {
      setValidationPct(e.data.pct)
      if (e.data.pct === -1) {
        worker.terminate()
        handleValidationResults({ ...e.data, rows: parseResults.data })
      }
    }
  }

  return (
    <>
      {uploadResultsMessageBox}
      {undefined === parseResults &&
        <div>
          {dropzoneContainer}
        </div>
      }
      <div>
        {(undefined !== parseResults) && <>
          {importErrorsMessageBox}
          {importWarningsMessageBox}
          {importContactsMessageBox}
          {contactsTable}
        </>}
      </div>
      {(undefined === parseResults) && howItWorksSection}
    </>
  )
}

export default CSVImporter