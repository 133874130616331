import PropTypes from 'prop-types'
import React from 'react'
//import BlastStepAddressRecipients from './BlastStepAddressRecipients'
import BlastStepAddressTesters from './BlastStepAddressTesters'

const BlastStepAddress = () => {

    // const testersTab = () => <BlastStepAddressTesters></BlastStepAddressTesters>
    // const targetedTab = () => <BlastStepAddressRecipients blast={blast} />
    // const panes = [
    //     { menuItem: 'Testers', render: testersTab },
    //     { menuItem: 'Targeted Contacts', render: targetedTab },
    // ]
    // const AddressStepContent = <>
    //     <Tab panes={panes} />
    // </>

    return (
        <>
            <BlastStepAddressTesters></BlastStepAddressTesters>
        </>
    )
}

BlastStepAddress.propTypes = {
    blast: PropTypes.any
}

export default BlastStepAddress