import React from 'react'

const About = () =>
    <>
        <div>
            <header className="bg-white shadow">
                <div className="px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold text-gray-900">
                        About
                    </h1>
                </div>
            </header>
            <main>
                <div className="py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="px-4 py-6 prose sm:px-0">
                        <h4>Design Goals</h4>
                        <ul>
                            <li>Secure</li>
                            <li>Scalable</li>
                            <li>Simple</li>
                            <li>Low operational cost</li>
                            <li>Compliments your favorite tools</li>
                        </ul>
                        <h4>Blazingly buzzword compliant</h4>
                        <p>
                            Ampymail is built using Amazon AWS Amplify and will scale to handle rediculously
                            large campaigns without breaking a sweat. We let Amazon&rsquo;s Simple Email
                            Service (SES) do the heavy lifting. We catch bounce, complaint, send, delivery,
                            open, and click events using Simple Notification Servcie (SNS) and push data
                            into a DynamoDB database using GraphQL. Email assets stored in an S3 bucket and
                            hosted to the public via CloudFront.
                            We recommend adding CloudWatch for additional monitoring,
                            CloudTrail for auditing, and Cloud Angels for prayer.
                        </p>
                        <section>
                            <h2>Attributions</h2>
                            <p>Thank you to the following individuals and organizations. Your software makes our software better!</p>
                            <h4>Font Awesome</h4>
                            <a href="https://fontawesome.com/">Website</a> &nbsp; / &nbsp;
                            <a href="https://fontawesome.com/license/free">License</a>
                        </section>

                    </div>
                </div>
            </main>
        </div>
    </>

export default About