import { AuthState } from '@aws-amplify/ui-components'
import { Auth, Hub } from 'aws-amplify'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
const AuthContext = React.createContext()

const AUTHENTICATOR_AUTHSTATE = 'amplify-authenticator-authState'


const AuthProvider = props => {
	const [auth, setAuth] = useState({})
	useEffect(() => {
		Auth.currentAuthenticatedUser()
			.then(user => {
				//console.log('Current user: ', user)
				const groups = user.signInUserSession.accessToken.payload["cognito:groups"]
				const group = groups && groups.filter(group => group !== "AmpyAdmin")[0]
				localStorage.setItem(AUTHENTICATOR_AUTHSTATE, AuthState.SignedIn)
				setAuth({ state: AuthState.SignIn, user, group })
			})
			.catch(() => localStorage.getItem(AUTHENTICATOR_AUTHSTATE))
			.then(cachedAuthState => ((cachedAuthState === AuthState.SignedIn) || (cachedAuthState === 'signedIn')) && Auth.signOut())
	}, [])
	useEffect(() => {
		Hub.listen('auth', ({ payload }) => {
			const { event, data } = payload
			if (event === AuthState.SignOut || event === 'signOut') {
				setAuth({ state: event, user: null, group: null })
				localStorage.removeItem(AUTHENTICATOR_AUTHSTATE)
				return
			}
			const groups = data && data.signInUserSession && data.signInUserSession.accessToken.payload["cognito:groups"]
			const group = groups && groups.filter(group => group !== "AmpyAdmin")[0]
			localStorage.setItem(AUTHENTICATOR_AUTHSTATE, AuthState.SignedIn)
			setAuth({ state: event, user: data, group })
		})
	}, [])
	return <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
	children: PropTypes.any,
}

export { AuthContext, AuthProvider }
