import { API, graphqlOperation } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { listSegments } from '../graphql/queries'

const useSegments = () => {
    const [items, setItems] = useState([])

    useEffect(async () => {
        try {
            //TODO: check nextToken and fetch ALL segments
            const gqlData = await API.graphql(graphqlOperation(listSegments))
            const items = gqlData.data.listSegments.items
            setItems(items)
        } catch (err) {
            console.log('error fetching segments', err)
            alert('ERROR retrieving data from the database. (segments)')
        }
    }, [])

    return items
}

export default useSegments