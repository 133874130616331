import { API, graphqlOperation } from 'aws-amplify'
import React from 'react'
import { useHistory } from "react-router-dom"
import { createSegment } from '../graphql/mutations'
import { AuthContext } from '../helpers/AuthContext'
import useSegments from '../hooks/useSegments'

const Segments = () => {
    const auth = React.useContext(AuthContext)
    const history = useHistory()
    const segments = useSegments()

    const createNewSegment = () => {
        async function addSegment(segment) {
            try {
                segment.groups = auth.group
                const gqlData = await API.graphql(graphqlOperation(createSegment, {
                    input: segment
                }))
                const id = gqlData.data.createSegment.id
                history.push(`/segments/${id}`)
            } catch (err) {
                console.log('error creating segment:', err)
                alert('Error')
            }
        }
        // Add new project
        addSegment({
            title: 'Segment Title',
            description: 'Description of your segment.',
            rules: JSON.stringify({
                "recentPlayDate": {
                    "dateIsSameDay": "2019-03-23",
                    "dateIsBetween": { startDate: "2019-03-01", endDate: "2021-02-28" }
                },
                "metaDoesNotExist": {
                    "stringNE": "ABCDEDG",
                    "isEmpty": true,
                    "booleanIsTrue": false
                },
                "enabled": {
                    "booleanIsTrue": true
                },
                "noEmail": {
                    "booleanIsTrue": false
                },
                "offerId2": {
                    "stringEQ": "DIN2104A",
                    "stringGT": "ABC",
                    "stringGE": "DIN2000A",
                    "stringLT": "EFT",
                    "stringLE": "DIN2222A",
                    "stringStarts": "DIN",
                    "stringContains": "2104",
                    "stringEnds": "104A",
                    "stringNE": "hello",
                    "stringNStarts": "doesntStartWith",
                    "stringNContains": "some value",
                    "stringNEnds": "doesntEndWith",
                    "isEmpty": false,

                    "stringIEQ": "din2104a",
                    "stringINE": "hello",
                    "stringIGT": "abc",
                    "stringIGE": "din2000a",
                    "stringILT": "eft",
                    "stringILE": "din2222a",
                    "stringIStarts": "din",
                    "stringIContains": "2104",
                    "stringIEnds": "104a",
                    "stringINStarts": "doesntStartWith",
                    "stringINContains": "some value",
                    "stringINEnds": "doesntEndWith"
                }
            })
        })
    }

    const createNewSegmentButton = <button
        type="button"
        onClick={createNewSegment}
        className="px-4 mx-2 text-white transition duration-500 bg-red-500 border border-red-500 rounded-md select-none ease hover:bg-red-600 focus:outline-none focus:shadow-outline">
        <svg
            className="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
        </svg>
        <span className='sr-only'>New Segment</span>
    </button>

    const header = <header className="shadow bg-gray-50">
        <div className="items-center justify-between px-4 py-6 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between">
                <div>
                    <h1 className="text-3xl font-bold text-gray-900">Segments</h1>
                </div>
                <div className="flex flex-row">
                    {createNewSegmentButton}
                </div>
            </div>
        </div>
    </header>

    return (<>
        <div>
            {header}
            <main>
                <div className="py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">
                        <div className="overflow-y-auto border-b h-96">
                            <table className="w-full table-auto" >
                                <thead>
                                    <tr className="font-bold bg-gray-200">
                                        <td className='p-2'>Title</td>
                                        <td className='p-2'>Description</td>
                                        <td className='p-2'>Last Updated</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(0 === segments.length) && <tr><td>No segments found.</td></tr>}
                                    {
                                        segments.map(({ id, title, description, updatedAt }) =>
                                            <tr key={id}
                                                onClick={() => { history.push(`/segments/${id}`) }}
                                                className='even:bg-gray-100 hover:bg-yellow-100'
                                                style={{ cursor: 'cell' }}
                                            >
                                                <td className='p-1'>{title}</td>
                                                <td className='p-1'>{description}</td>
                                                <td className='p-1'>{updatedAt}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </>)
}

export default Segments