import PropTypes from 'prop-types'
import React from 'react'

const Layout = props => (
    // <Container style={{ marginTop: '1em', marginBottom: '1em', minHeight: '75vh' }}>{props.children}</Container>
    // <div style={{ marginTop: '1em', marginBottom: '1em', minHeight: '82vh' }}>{props.children}</div>
    <div className="flex-grow">{props.children}</div>
)

Layout.propTypes = {
    children: PropTypes.any
}

export default Layout