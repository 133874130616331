import PropTypes from 'prop-types'
import React from 'react'

const IconButton = ({ onClick, className, svgIcon }) => <button className={className} onClick={onClick}>{svgIcon}</button>

IconButton.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    svgIcon: PropTypes.node,
}

export default IconButton
