/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendSendGridEmail = /* GraphQL */ `
  mutation SendSendGridEmail(
    $blastId: String
    $audience: String
    $tags: [String]
  ) {
    sendSendGridEmail(blastId: $blastId, audience: $audience, tags: $tags)
  }
`;
export const deleteSendGridBatchId = /* GraphQL */ `
  mutation DeleteSendGridBatchId($blastId: String) {
    deleteSendGridBatchId(blastId: $blastId)
  }
`;
export const addMxRecords = /* GraphQL */ `
  mutation AddMxRecords($mxRecords: [String]) {
    addMxRecords(mxRecords: $mxRecords)
  }
`;
export const updateMxRecords = /* GraphQL */ `
  mutation UpdateMxRecords {
    updateMxRecords
  }
`;
export const createMetadataMapping = /* GraphQL */ `
  mutation CreateMetadataMapping(
    $input: CreateMetadataMappingInput!
    $condition: ModelMetadataMappingConditionInput
  ) {
    createMetadataMapping(input: $input, condition: $condition) {
      id
      groups
      fieldname
      type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateMetadataMapping = /* GraphQL */ `
  mutation UpdateMetadataMapping(
    $input: UpdateMetadataMappingInput!
    $condition: ModelMetadataMappingConditionInput
  ) {
    updateMetadataMapping(input: $input, condition: $condition) {
      id
      groups
      fieldname
      type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteMetadataMapping = /* GraphQL */ `
  mutation DeleteMetadataMapping(
    $input: DeleteMetadataMappingInput!
    $condition: ModelMetadataMappingConditionInput
  ) {
    deleteMetadataMapping(input: $input, condition: $condition) {
      id
      groups
      fieldname
      type
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createMxRecord = /* GraphQL */ `
  mutation CreateMxRecord(
    $input: CreateMxRecordInput!
    $condition: ModelMxRecordConditionInput
  ) {
    createMxRecord(input: $input, condition: $condition) {
      id
      response
      createdAt
      updatedAt
    }
  }
`;
export const updateMxRecord = /* GraphQL */ `
  mutation UpdateMxRecord(
    $input: UpdateMxRecordInput!
    $condition: ModelMxRecordConditionInput
  ) {
    updateMxRecord(input: $input, condition: $condition) {
      id
      response
      createdAt
      updatedAt
    }
  }
`;
export const deleteMxRecord = /* GraphQL */ `
  mutation DeleteMxRecord(
    $input: DeleteMxRecordInput!
    $condition: ModelMxRecordConditionInput
  ) {
    deleteMxRecord(input: $input, condition: $condition) {
      id
      response
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      groups
      contactID
      blastID
      eventType
      subType
      timestamp
      reason
      status
      response
      attempt
      asm_group_id
      useragent
      ip
      url
      imbAdr
      imbInHomeDate
      createdAt
      updatedAt
      owner
      Contact {
        items {
          id
          groups
          dncEmail
          ccid
          email
          name
          tags
          metadata
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      groups
      contactID
      blastID
      eventType
      subType
      timestamp
      reason
      status
      response
      attempt
      asm_group_id
      useragent
      ip
      url
      imbAdr
      imbInHomeDate
      createdAt
      updatedAt
      owner
      Contact {
        items {
          id
          groups
          dncEmail
          ccid
          email
          name
          tags
          metadata
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      groups
      contactID
      blastID
      eventType
      subType
      timestamp
      reason
      status
      response
      attempt
      asm_group_id
      useragent
      ip
      url
      imbAdr
      imbInHomeDate
      createdAt
      updatedAt
      owner
      Contact {
        items {
          id
          groups
          dncEmail
          ccid
          email
          name
          tags
          metadata
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      groups
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      groups
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      groups
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSegment = /* GraphQL */ `
  mutation CreateSegment(
    $input: CreateSegmentInput!
    $condition: ModelSegmentConditionInput
  ) {
    createSegment(input: $input, condition: $condition) {
      id
      groups
      title
      description
      rules
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSegment = /* GraphQL */ `
  mutation UpdateSegment(
    $input: UpdateSegmentInput!
    $condition: ModelSegmentConditionInput
  ) {
    updateSegment(input: $input, condition: $condition) {
      id
      groups
      title
      description
      rules
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSegment = /* GraphQL */ `
  mutation DeleteSegment(
    $input: DeleteSegmentInput!
    $condition: ModelSegmentConditionInput
  ) {
    deleteSegment(input: $input, condition: $condition) {
      id
      groups
      title
      description
      rules
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      groups
      dncEmail
      ccid
      email
      name
      tags
      metadata
      createdAt
      updatedAt
      Events {
        items {
          id
          groups
          contactID
          blastID
          eventType
          subType
          timestamp
          reason
          status
          response
          attempt
          asm_group_id
          useragent
          ip
          url
          imbAdr
          imbInHomeDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      groups
      dncEmail
      ccid
      email
      name
      tags
      metadata
      createdAt
      updatedAt
      Events {
        items {
          id
          groups
          contactID
          blastID
          eventType
          subType
          timestamp
          reason
          status
          response
          attempt
          asm_group_id
          useragent
          ip
          url
          imbAdr
          imbInHomeDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      groups
      dncEmail
      ccid
      email
      name
      tags
      metadata
      createdAt
      updatedAt
      Events {
        items {
          id
          groups
          contactID
          blastID
          eventType
          subType
          timestamp
          reason
          status
          response
          attempt
          asm_group_id
          useragent
          ip
          url
          imbAdr
          imbInHomeDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const createBlast = /* GraphQL */ `
  mutation CreateBlast(
    $input: CreateBlastInput!
    $condition: ModelBlastConditionInput
  ) {
    createBlast(input: $input, condition: $condition) {
      id
      groups
      status
      fromName
      fromEmail
      replyToName
      replyToEmail
      emailProgram
      titleCode
      isIMSight
      imsightCapturePII
      sendDateTime
      title
      shortDescription
      templateId
      sgBatchId
      templateVars
      Segments {
        id
        groups
        title
        description
        rules
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      Events {
        items {
          id
          groups
          contactID
          blastID
          eventType
          subType
          timestamp
          reason
          status
          response
          attempt
          asm_group_id
          useragent
          ip
          url
          imbAdr
          imbInHomeDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateBlast = /* GraphQL */ `
  mutation UpdateBlast(
    $input: UpdateBlastInput!
    $condition: ModelBlastConditionInput
  ) {
    updateBlast(input: $input, condition: $condition) {
      id
      groups
      status
      fromName
      fromEmail
      replyToName
      replyToEmail
      emailProgram
      titleCode
      isIMSight
      imsightCapturePII
      sendDateTime
      title
      shortDescription
      templateId
      sgBatchId
      templateVars
      Segments {
        id
        groups
        title
        description
        rules
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      Events {
        items {
          id
          groups
          contactID
          blastID
          eventType
          subType
          timestamp
          reason
          status
          response
          attempt
          asm_group_id
          useragent
          ip
          url
          imbAdr
          imbInHomeDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteBlast = /* GraphQL */ `
  mutation DeleteBlast(
    $input: DeleteBlastInput!
    $condition: ModelBlastConditionInput
  ) {
    deleteBlast(input: $input, condition: $condition) {
      id
      groups
      status
      fromName
      fromEmail
      replyToName
      replyToEmail
      emailProgram
      titleCode
      isIMSight
      imsightCapturePII
      sendDateTime
      title
      shortDescription
      templateId
      sgBatchId
      templateVars
      Segments {
        id
        groups
        title
        description
        rules
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      Events {
        items {
          id
          groups
          contactID
          blastID
          eventType
          subType
          timestamp
          reason
          status
          response
          attempt
          asm_group_id
          useragent
          ip
          url
          imbAdr
          imbInHomeDate
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
