import PropTypes from 'prop-types'
import React, { useReducer } from 'react'
//import { Link } from 'react-router-dom'
import EventsTable from '../components/EventsTable'
import ToggleTagsContainer from '../components/ToggleTagsContainer'
import useContact from '../hooks/useContact'
import TagContext from '../hooks/useTagContext'
import useTags from '../hooks/useTags'
import md5 from 'crypto-js/md5'

const Contact = ({ contactId }) => {
    const contact = useContact(contactId)
    //console.log('contact', contact)
    const tags = useTags()
    const initialState = {
        tags: tags,
        //startTags: [],
        colors: ['gray', 'green'],
        icons: [null, null],
        addTags: new Set(),
        removeTags: new Set()
    }

    const reducer = (state, action) => {
        //console.log('action', action)
        const nextState = { ...state }
        switch (action.type) {
            case 'TagsUpdated':
                nextState.tags = action.tags
                break
            // case 'ContactUpdated':
            //     console.log('updating start tags to ', action.tags)
            //     nextState.startTags = action.tags
            //     break
            case 'TagClick':
                switch (action.color) {
                    case 0: // ignore tag
                        nextState.addTags.delete(action.name)
                        nextState.removeTags.delete(action.name)
                        break
                    case 1: // add tag to contact
                        nextState.addTags.add(action.name)
                        nextState.removeTags.delete(action.name)
                        break
                    case 2: //remove tag from contact
                        nextState.addTags.delete(action.name)
                        nextState.removeTags.add(action.name)
                        break
                    default:
                        alert('dispatch error')
                        break
                }
                alert('tag changes not saved yet')
                break
            default:
                break
        }
        return nextState
    }
    const [state, dispatch] = useReducer(reducer, initialState)

    React.useEffect(() => {
        if (contact && contact.tags && contact.tags.length > 0) {
            dispatch({ type: 'TagsUpdated', tags: contact.tags })
        }
        return () => {
            //cleanup
        }
    }, [contact])

    // React.useEffect(() => {
    //     dispatch({ type: 'TagsUpdated', tags: tags })
    //     return () => {
    //         //cleanup
    //     }
    // }, [tags])

    // React.useEffect(() => {
    //     dispatch({ type: 'ContactUpdated', tags: contact.tags })
    //     return () => {
    //         //cleanup
    //     }
    // }, [contact])

    let metadata = contact?.metadata ? JSON.parse(contact.metadata) : {}
    let metakeys = Object.keys(metadata)
    const metadataTable =
        <div className='container p-2 mt-2 border bg-gray-50'>
            <h2 className='mt-2 text-xl'>Metadata</h2>
            {((0 !== metakeys.length) ?
                <table className='w-full table-auto'>
                    <tbody>
                        {metakeys.map(key =>
                            <tr key={key} className='even:bg-gray-200'>
                                <td className='p-1 font-medium'>{key}</td>
                                <td className="pl-1">{metadata[key]}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                : <p>No metadata for this contact</p>)}
        </div>

    const infoTable =
        <div className='container p-2 mt-2 border bg-gray-50'>
            <h2 className='text-xl'>Contact Info</h2>
            <table className='w-full table-auto'>
                <tbody>
                    <tr>
                        <td className='p-1 font-medium'>Customer ID</td>
                        <td className="pl-1">{contact.ccid}</td>
                    </tr>
                    <tr>
                        <td className='p-1 font-medium'>Name</td>
                        <td className="pl-1">{contact.name}</td>
                    </tr>
                    <tr>
                        <td className='p-1 font-medium'>Email</td>
                        <td className="pl-1">{contact.email}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    const tagsSegment =
        <div className='container p-2 border bg-gray-50'>
            <h2 className='text-xl'>Tags</h2>
            <TagContext.Provider value={{ state, dispatch }}>
                <ToggleTagsContainer />
            </TagContext.Provider>
        </div>

    const downloadEvents = () => {
        alert('TODO: download all events for this contact')
    }

    const downloadEventsButton = <button
        type="button"
        onClick={downloadEvents}
        className="px-4 py-2 mx-2 text-white transition duration-500 bg-red-500 border border-red-500 rounded-md select-none ease hover:bg-red-700 focus:outline-none focus:shadow-outline">
        <svg
            className="inline-block w-6 h-6"
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <span>download events</span>
    </button>


    const contactHash = contact && contact.email && md5(contact.email.trim().toLowerCase()).toString()
    const header = <header className="shadow bg-gray-50">
        <div className="px-4 py-6 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between">
                <div className='flex items-center'>
                    {contactHash && <img className="mr-2 rounded-lg w-14 h-14 " src={`https://www.gravatar.com/avatar/${contactHash}?d=wavatar`} alt="" />}
                    <div>
                        {contact && contact.name && contact.email && <div>
                            <h1 className="text-3xl font-bold text-gray-900">{contact.name}</h1>
                            <h2 className="text-xl font-bold text-gray-500">{contact.email}</h2>
                        </div>}
                        {contact && contact.name && !contact.email && <h1 className="text-3xl font-bold text-gray-900">{contact.name}</h1>}
                        {contact && !contact.name && contact.email && <h1 className="text-3xl font-bold text-gray-900">{contact.email}</h1>}
                    </div>
                </div>
                <div>
                    {downloadEventsButton}
                </div>
            </div>
        </div>
    </header>

    const main =
        <main>
            <div className="py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="px-4 py-6 sm:px-0">
                    {tagsSegment}
                    {infoTable}
                    {metadataTable}
                    {contact && contact.Events && <EventsTable events={contact.Events.items} />}
                </div>
            </div>
        </main>

    return <>
        {header}
        {main}
    </>
}

Contact.propTypes = {
    contactId: PropTypes.any
}

export default Contact