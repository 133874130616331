import React from 'react'
import { Link } from "react-router-dom"


const NoMatch = () => (
    <>
        <>
            <div>
                <header className="bg-white shadow">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">
                            Oops!
                    </h1>
                    </div>
                </header>
                <main>
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <div className="px-4 py-6 sm:px-0">
                            <p>If you just found a ICON-BUG please <Link to="/contact">let us know</Link>!</p>
                        </div>
                    </div>
                </main>
            </div>
        </>
    </>
)
export default NoMatch