import PropTypes from 'prop-types'
import React from 'react'
import Moment from 'react-moment'

// additional event fields
// timestamp: AWSTimestamp
// status: String
// attempt: Int
// asm_group_id: Int

const EventsTable = props => {
    const { events } = props
    //console.log('events', events)
    const eventsTable =
        <div className='container p-2 mt-2 border bg-gray-50'>
            <h2 className='text-xl'>Event History</h2>
            <div
                className='text-xs overscroll-auto'
                style={{ overflowY: 'auto', maxHeight: "90vh" }}>
                <table className='w-full table-auto'>
                    <thead className='font-bold'>
                        <tr className='p-1'>
                            <td className='w-36'>Date</td>
                            <td className='w-16'>Blast</td>
                            <td className='w-8'>Event</td>
                            <td className='w-8'>Type</td>
                            <td className='w-10'>Reason</td>
                            <td className='w-10'>Response</td>
                            <td className='w-8'>IP</td>
                            <td className='w-20'>Link</td>
                            <td className='w-20'>User-Agent</td>
                        </tr>
                    </thead>
                    <tbody>
                        {(events && events.length > 0) ? events.map((event, idx) => {
                            return (
                                <tr key={idx} className='even:bg-gray-200'>
                                    <td className='w-32 p-1'><span className='date'><Moment date={event.createdAt} /></span></td>
                                    <td className='w-16 p-1'>{event.blastID}</td>
                                    <td className='w-8 p-1'>{event.eventType}</td>
                                    <td className='w-8 p-1'>{event.subType}</td>
                                    <td className='w-10 p-1'>{event.reason}</td>
                                    <td className='w-10 p-1'>{event.response}</td>
                                    <td className='w-8 p-1'>{event.ip}</td>
                                    <td className='w-20 p-1'>{event.url}</td>
                                    <td className='w-20 p-1'>{event.useragent}</td>
                                </tr>
                            )
                        }) :
                            <tr>
                                <td>No events found</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>

    return eventsTable
}

EventsTable.propTypes = {
    events: PropTypes.arrayOf(PropTypes.object)
}

export default EventsTable
