import React, { useContext } from 'react'
import ToggleTag from '../components/ToggleTag'
import TagContext from '../hooks/useTagContext'




const ToggleTagsContainer = () => {
    const { state } = useContext(TagContext)
    const tags = state.tags.map(tag => ({ name: tag, color: 0 }))
    return <>
        {(tags && tags.length > 0)
            ? tags.map(({ name, color }, idx) =>
                <ToggleTag
                    key={idx}
                    tagName={name}
                    tagColor={color}
                />)
            : <p>No tags found</p>
        }
    </>
}


ToggleTagsContainer.propTypes = {
    //tags: PropTypes.arrayOf(PropTypes.object)
}

export default ToggleTagsContainer
