import { Auth } from 'aws-amplify'
//import { AmplifySignOut } from '@aws-amplify/ui-react'
import PropTypes from 'prop-types'
import React, { useState, useContext } from 'react'
//import { NavLink, useLocation } from "react-router-dom"
import { NavLink } from "react-router-dom"
import { AuthContext } from '../helpers/AuthContext'
import logo from '../logo-white.png'
import md5 from 'crypto-js/md5'


const NavigationBar = () => {
    const [isNavOpen, setIsNavOpen] = useState(false)
    const [isProfileOpen, setIsProfileOpen] = useState(false)
    const auth = useContext(AuthContext)
    //const location = useLocation()
    //console.log(`location`, location)
    const hash = md5(auth?.user?.attributes.email.trim().toLowerCase()).toString()

    async function signOut() {
        try {
            await Auth.signOut()
        } catch (error) {
            console.log('error signing out: ', error)
        }
    }

    return <>
        <nav className="bg-red-800">
            <div className="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-16">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        <button type="button"
                            onClick={() => {
                                setIsNavOpen(p => !p)
                            }}
                            className="inline-flex items-center justify-center p-2 text-red-400 rounded-md hover:text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <svg className={`${isNavOpen ? 'hidden' : 'block'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                            <svg className={`${isNavOpen ? 'block' : 'hidden'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start">
                        <div className="flex items-center flex-shrink-0">
                            {/* <img className="block w-auto h-8 lg:hidden" src={logo} alt="Ampymail Logo" />
                            <img className="hidden w-auto h-8 lg:block" src={logoWithLabel} alt="Ampymail Logo" /> */}
                            <NavLink to="/"><img className="block w-auto h-8" src={logo} alt="Ampymail Logo" /></NavLink>
                        </div>
                        <div className="hidden sm:block sm:ml-6">
                            <div className="flex space-x-4">
                                {/* <!-- Current: "bg-red-900 text-white", Default: "text-red-300 hover:bg-red-700 hover:text-white" --> */}
                                <NavLink to="/contacts" className="text-sm font-medium navlink">Contacts</NavLink>
                                <NavLink to="/segments" className="text-sm font-medium navlink">Segments</NavLink>
                                <NavLink to="/blasts" className="text-sm font-medium navlink">Blasts</NavLink>
                                <NavLink to="/about" className="text-sm font-medium navlink">About</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        <button className="p-1 text-red-400 bg-red-800 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-800 focus:ring-white">
                            <span className="sr-only">View notifications</span>
                            {/* <!-- Heroicon name: outline/bell --> */}
                            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                            </svg>
                        </button>

                        {/* <!-- Profile dropdown --> */}
                        <div className="relative ml-3">
                            <div>
                                <button
                                    onClick={() => {
                                        setIsProfileOpen(p => !p)
                                    }}
                                    type="button" className="flex text-sm bg-red-800 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-800 focus:ring-white" id="user-menu" aria-expanded="false" aria-haspopup="true">
                                    <span className="sr-only">Open user menu</span>
                                    <img className="w-8 h-8 rounded-full" src={`https://www.gravatar.com/avatar/${hash}?d=wavatar`} alt="" />
                                </button>
                            </div>
                            {isProfileOpen && <div className="absolute right-0 w-32 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                                <a href="#" className="block px-4 py-2 text-sm text-red-700 hover:bg-red-100" role="menuitem">Your Profile</a>
                                <a href="#" className="block px-4 py-2 text-sm text-red-700 hover:bg-red-100" role="menuitem">Settings</a>
                                <a onClick={signOut} className="block px-4 py-2 text-sm text-red-700 hover:bg-red-100" role="menuitem">Sign out</a>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Mobile menu, show/hide based on menu state. --> */}
            {isNavOpen && <div className="sm:hidden" id="mobile-menu">
                <div className="px-2 pt-2 pb-3 space-y-1">
                    {/* <!-- Current: "bg-red-900 text-white", Default: "text-red-300 hover:bg-red-700 hover:text-white" --> */}
                    <NavLink to="/contacts" onClick={() => { setIsNavOpen(false) }} className="block px-3 py-2 text-base font-medium rounded-md navlink">Contacts</NavLink>
                    <NavLink to="/segments" onClick={() => { setIsNavOpen(false) }} className="block px-3 py-2 text-base font-medium rounded-md navlink">Segments</NavLink>
                    <NavLink to="/blasts" onClick={() => { setIsNavOpen(false) }} className="block px-3 py-2 text-base font-medium rounded-md navlink">Blasts</NavLink>
                    <NavLink to="/about" onClick={() => { setIsNavOpen(false) }} className="block px-3 py-2 text-base font-medium rounded-md navlink">About</NavLink>
                </div>
            </div>}
        </nav>
        {!auth.group && <div
            className="p-4 m-4 font-normal bg-red-300 border-2 border-red-600 text-gray-50">
            HOT UPDATE FAIL. No user. Reload page.</div>}
    </>

}
NavigationBar.propTypes = {
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    children: PropTypes.any
}
export default NavigationBar