import { AmplifySignOut } from '@aws-amplify/ui-react'
import PropTypes from 'prop-types'
import React from 'react'
import { Route, Switch } from "react-router-dom"
import Footer from './components/PageParts/Footer'
import Layout from './components/PageParts/Layout'
import Page from "./components/PageParts/Page"
import NavigationBar from './components/NavigationBar'
import About from './pages/About'
import Blast from "./pages/Blast"
import Blasts from "./pages/Blasts"
import Contact from "./pages/Contact"
import Contacts from "./pages/Contacts"
import ContactsImport from "./pages/ContactsImport"
import ContactUs from "./pages/ContactUs"
import Home from "./pages/Home"
import NoMatch from "./pages/NoMatch"
import PrivacyPolicy from "./pages/Privacy"
import Segment from "./pages/Segment"
import Segments from "./pages/Segments"
import TermsOfUse from "./pages/Terms"
//import Test from "./pages/Test"

const AppRoutes = ({ user }) => {
    //console.log('user', user)
    const groups = user.signInUserSession && user.signInUserSession.accessToken.payload["cognito:groups"]
    const group = groups && groups.filter(group => group !== "AmpyAdmin")[0]
    //console.log('groups', groups)

    const [visible, setVisible] = React.useState(false)

    return (<>
        {group ?
            <div className="flex flex-col min-h-screen">
                <NavigationBar visible={visible} setVisible={setVisible} />

                <Page visible={visible} setVisible={() => setVisible(false)}>
                    <Layout>
                        <Switch>
                            <Route exact path='/' render={() => (<Home />)} />
                            <Route path='/about' render={() => (<About />)} />
                            <Route path='/contact' render={() => (<ContactUs />)} />
                            <Route path='/import-contacts' render={() => (<ContactsImport />)} />
                            <Route path='/terms' render={() => (<TermsOfUse />)} />
                            <Route path='/privacy' render={() => (<PrivacyPolicy />)} />
                            {/* <Route path='/test' render={() => (<Test />)} /> */}

                            <Route path='/contacts/:Id'
                                render={({ match }) => (
                                    <Contact contactId={match.params.Id} />
                                )}
                            />
                            <Route path='/contacts' render={() => (<Contacts />)} />

                            <Route path='/segments/:Id'
                                render={({ match }) => (
                                    <Segment segmentId={match.params.Id} />
                                )}
                            />
                            <Route exact path='/segments' render={() => (<Segments />)} />

                            <Route path='/blasts/:Id'
                                render={({ match }) => (
                                    <Blast blastId={match.params.Id} />
                                )}
                            />
                            <Route exact path='/blasts' render={() => (<Blasts />)} />

                            <Route render={() => (<NoMatch />)} />
                        </Switch>
                    </Layout>
                </Page>
                <Footer />
            </div>
            : <>
                <h1>Account must be associated with a brand.</h1>
                <AmplifySignOut />
            </>
        }
    </>)
}

AppRoutes.propTypes = {
    children: PropTypes.any,
    user: PropTypes.object
}

export default AppRoutes